<style lang="sass" scoped>
.scroll
  height: 300px
  // min-height: 300px
  overflow: scroll
  // max-height: 300px
.item
  border-left: solid 2px #fff
  font-size: 14px
  font-weight: 500
  cursor: pointer
  &.active
    border-left: solid 2px #007bff
  // &:hover
  //   border-left: solid 2px #007bff
  //   background-color: #fafafa
  //   cursor: pointer
  &.checked
    // border-left: solid 2px #fafafa
    border-left: solid 2px #fafafa
    background-color: #fafafa
    .check
      visibility: visible
  &.selected
    border-left: solid 2px #007bff
    // background-color: #fafafa
    background-color: #f0f0f0

.query
  font-size: 14px
  // padding: 2rem 1rem
.color-label
  display: inline-block
  width: 1rem
  height: 1rem
  float: left
  // color: transparent
.handle-grip
  opacity: 0
  margin-left: -5px
  cursor: move

.item
  &:hover
    .handle-grip
      opacity: 1
.tag-added
  background-color: mix(#fff, #B5EFDB, 50%)
  color: mix(#000, #B5EFDB, 50%)
.check
  // text-align: center
  vertical-align: top
  width: 28px
  display: inline-block
  font-size: 1.3rem
  line-height: 0.5rem
  visibility: hidden
.tag-item
  word-break: break-all
  max-width: 185px
  display: inline-block
  padding: 1px 5px
.single
  max-width: inherit
  // width: 99%
  // text-align: center
  // padding: 3px
  // margin-top: -3px !important
  margin-bottom: -3px !important
  .remove
    display: none
  // margin: -4px -2px 0 -2px
  // border-radius: 0px !important
</style>
<template lang="pug">
div(:key='ts')
  //- pre {{ cols_options_by_name }}
  //- :style='{backgroundColor: item.color}'
  //- pre {{values}}
  //- transition-group(type='transition' :name='removed ? "none" : "tag" ')
  span.tag-item.rounded.mr-1.mb-1.d-inline-block(:key='v' v-for='v in values' :style='{backgroundColor: color(v)}' :class='{"single":(col.max)}') {{v}}
    a.remove(href='#' v-if='removed' @click.prevent.stop='removed(col, v)' style='border-left: 0 !important'): b-icon-x(style='color: #fff')
  span(v-if='values.length == 0') -
</template>

<script>

import { isString, isArray, compact } from 'lodash'

export default {
  name: "CellTag",
  props: ['value', 'col', 'cols_options', 'removed'],
  components: {

  },
  watch: {
    // value() {
    //   this.ts = Date.now()
    // },
    // col: {
    //   handler() {
    //     this.ts = Date.now()
    //   },
    //   deep: true,
    // },
    'cols_options'() {
      this.ts = Date.now()
      // this.cols_options_by_label = keyBy(this.cols_options'], 'label')
    },
  },
  computed: {
    cols_options_by_name() {
      const v = {}
      for (const o in this.cols_options) {
        const e = this.cols_options[o]
        v[e.name] = e
      }
      return v
    },
    values() {
      if (!this.value) return []
      const sv = String(this.value) || ''
      const v = compact(sv.split(',').map(e => e.trim()))
      if (this.col.max === 1) {
        return v.slice(0, 1)
      }
      return v
    },
  },
  data() {
    return {
      ts: Date.now(),
      // cols_options_by_label: {},
    }
  },
  mounted() {

  },
  methods: {
    color(v) {
      if (!v) return '#999'
      const o = this.cols_options_by_name[v.trim()]
      return o ? o.color : '#999'
    }
  },
};
</script>
