<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
.menu-bar
  height: 40px
.frame
  width: calc(100vw - 300px)
  height: calc(100vh - 40px)
  overflow: scroll
.table-project
  width: 3000px
  border-color: #d4d4d4
  font-size: 14px
  border-width: 1px 0px 1px 0px
  th, .th
    padding: 0.5rem
    &.header
      color: #999
      font-weight: normal
      &:hover
        background-color: #f0f0f0
        cursor: pointer
  .th-check
    width: 40px
    padding-top: 7px
    padding-left: 5px
    text-align: center
  .td-check
    width: 40px
    padding: 7px 0px 7px 6px
    text-align: center
  tbody
    tr
      td
        padding: 0.5rem

      &.hover
        // cursor: pointer
        .hover-visible
          visibility: hidden
        &:hover
          box-shadow: 0px 2px 5px -2px rgba(0,0,0,0.5)
          .hover-visible
            visibility: visible

        &.row-selected
          background-color: mix(#007bff, #f0f0f0, 8%)

          td
            border-top-color: mix(#007bff, #f0f0f0, 20%)

      .td
        overflow: hidden
        span
          width: inherit
          display: block
          overflow: hidden
          white-space: nowrap
.col-100
  max-width: 100px
  display: inline-flex
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
.col-150
  max-width: 150px
  display: inline-flex
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
.card-project-record
  font-size: 14px
  &:hover
    background-color: rgba(darken(#f8f9fa, 50%), .03)
    cursor: pointer
.th-group
  // text-align: center
  // background-color: mix(#f1c40f, #f8f9fa, 20%)
  font-weight: 600 !important
  // background-color: #f8f9fa
  // z-index: 10
  // left: 0px
.td-group
  // text-align: center
  color: #555
  // color: #999
  font-weight: 600 !important
  // background-color: #f8f9fa
  // background-color: rgba(darken(#f8f9fa, 50%), .03)
  // z-index: 10
  // left: 0px
  // color: mix(#f1c40f, #555, 50%)
</style>
<template lang="pug">
div.async(:class='{done: done}' @click='blur_popover')
  .menu-bar.d-flex
    .p-2
      button.btn.btn-default(@click.stop='' type='button') 연락처
      b-spinner.ml-2.async(label='가져오는 중...' small variant='secondary' :class='{done:loading}')

    div.pt-2.d-flex
      button.btn.btn-default.opacity-50(type='button' @click.stop='open_filter_modal') 검색
      template(v-if='property && property.contact_config && property.contact_config.filters && property.contact_config.filters.length')
        template(v-for='(f, $index) in property.contact_config.filters')
          .btn-group
            button.btn.btn-default.bg-white(type='button' @click='open_filter(f)' :class='[ (current_filter.ts && current_filter.ts == f.ts) ? "text-primary" : "opacity-50"] ')
              span.col-100 {{f.name}}
              small.opacity-50(v-show='current_filter.ts && current_filter.ts == f.ts'): b-icon-three-dots.ml-1(:id='`opt_filter_${$index}`' @click.stop='')
          b-popover(:ref='`opt_filter_${$index}`' :target='`opt_filter_${$index}`' triggers='click ' no-fade placement='bottom' custom-class='popover-dropdown')
            button.btn.btn-default.btn-block.text-left.opacity-50.m-0(type='button' @click='clear_current_filter')
              b-icon-x.mr-2.opacity-50
              | 선택 취소
            button.btn.btn-default.text-danger.btn-block.text-left.m-0(type='button' @click='delete_current_filter')
              b-icon-trash.mr-2.opacity-50
              | 삭제
            .border-top
            .p-2
              input.form-control.form-control-sm(type='text' v-model.lazy='f.name' placeholder='나의 필터')
            .px-2.pb-2
              button.btn.btn-primary.btn-block.m-0(type='button' @click='save_current_filter' :disabled='f.name.length==0') 저장

      b-popover(ref='opt_filter_add' target='opt_filter_add' triggers='click blur' no-fade placement='bottom' custom-class='popover-dropdown')
        span.dropdown-label.px-2 필터이름
        .p-2
          input.form-control.form-control-sm(type='text' v-model='new_filter_name' placeholder='나의 필터')
        .border-top
        .p-2
          button.btn.btn-primary.btn-block(type='button' @click='save_filter' :disabled='new_filter_name.length==0') 저장

      modal.modal-name-filter(name='filter' transition='filter' :shiftY='0.2' :height='`auto`' :scrollable='true')
        contact-search-filter(:documents='$store.state.documents' @updated='filter_did_updated' :default-selected='selected_filter')

    .p-2.ml-auto
      button.btn.btn-default.text-primary(type='button' v-show='filters.length' id='opt_filter_add' @click.stop='')
        b-icon-plus.opacity-50
        | 필터추가
      button.btn.btn-default.opacity-50(type='button' id='opt_more_table')
        b-icon-three-dots
      //- button.btn.btn-default(type='button' @click='create') 추가
      b-popover(ref='opt_more_table' target='opt_more_table' triggers='click' no-fade placement='bottom' custom-class='popover-dropdown popover-dropdown-sm')
        button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0.pr-4(type='button' v-b-toggle.project-collapse)
          b-icon-search.mr-2.opacity-50
          span 프로젝트 열기
        button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0.pr-4(type='button' @click='export_file("csv")')
          b-icon-file-text.mr-2.opacity-50
          span CSV 다운로드
        button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='export_file("xlsx")')
          b-icon-file-spreadsheet.mr-2.opacity-50
          span 엑셀 다운로드
        button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='export_file("html")')
          b-icon-file-code.mr-2.opacity-50
          span HTML 다운로드
  .frame
    div.ml-2.d-flex.flex-wrap(style='padding-top: 12px')
      div.mr-2.pb-2(v-if='filters && filters.length' v-for='item in filters')
        button.btn.btn-default.btn-sm.item-filter.px-2.py-1.shadow-sm.border.text-secondary(type='button' @click.stop='open_selected_filter_modal(item)')
          b-icon-person-fill(v-if='item.prekey == "search.customer"')
          span(v-if='item.prekey == "search.field"') {{item.col.label}}:&nbsp;
          span(v-else-if='item.key == "search.field.value_not_contain"') (제외)&nbsp;
          span(v-else-if='item.key == "search.field.value_is_empty"') (비어있음)&nbsp;
          span(v-else-if='item.key == "search.field.value_isnt_empty"') (비어있지않음)&nbsp;
          span(v-else-if='item.key == "search.row.not_in_ids"') (고객아이디 제외)&nbsp;
          span(v-else-if='item.key == "search.row.in_ids"') (고객아이디 지정)&nbsp;
          span(v-else-if='item.key == "search.project.include"') 포함:&nbsp;
            span.col-100 {{item.document_name}}
          span(v-else-if='item.key == "search.project.exclude"') 제외:&nbsp;
            span.col-100 {{item.document_name}}
          span(v-else-if='item.key == "search.project.value"')
            span.col-100 {{item.document_name}}:&nbsp;
            span.col-100 {{item.value}}
          span(v-else-if='item.prekey == "search.row"') {{item.value.length}}건&nbsp;
          span(v-else) {{item.value}}
          b-icon-x.item-clear(@click.prevent.stop='clear_filter(item)')
        template(v-for='subitem in item.sub_or')
          button.btn.btn-default.btn-sm.item-filter.px-2.py-1.shadow-sm.border.text-secondary(type='button' @click.stop='open_selected_filter_modal(subitem)')
            b-icon-person-fill(v-if='subitem.prekey == "search.customer"')
            span(v-if='subitem.prekey == "search.field"') {{subitem.col.label}}:&nbsp;
            span(v-else-if='subitem.key == "search.field.value_not_contain"') (제외)&nbsp;
            span(v-else-if='subitem.key == "search.field.value_is_empty"') (비어있음)&nbsp;
            span(v-else-if='subitem.key == "search.field.value_isnt_empty"') (비어있지않음)&nbsp;
            span(v-else-if='subitem.key == "search.row.not_in_ids"') (고객아이디 제외)&nbsp;
            span(v-else-if='subitem.key == "search.row.in_ids"') (고객아이디 지정)&nbsp;
            span(v-else-if='subitem.key == "search.project.include"') 포함:&nbsp;
              span.col-100 {{subitem.document_name}}
            span(v-else-if='subitem.key == "search.project.exclude"') 제외:&nbsp;
              span.col-100 {{subitem.document_name}}
            span(v-else-if='subitem.key == "search.project.value"')
              span.col-100 {{subitem.document_name}}:&nbsp;
              span.col-100 {{subitem.value}}
            span(v-else-if='subitem.prekey == "search.row"') {{subitem.value.length}}건&nbsp;
            span(v-else-if='!subitem.key') (없음)
            span(v-else) {{subitem.value}}
            b-icon-x.item-clear(@click.prevent.stop='clear_filter(subitem)')
        button.btn.btn-default.btn-sm.item-filter.px-2.py-1.shadow-sm.border.text-secondary.opacity-50(type='button' @click.stop='or_filter(item)') 또는
    b-collapse#project-collapse
      div.px-2
        .d-flex.bg-light.p-2
          div.w-100
            input.form-control.form-control-sm(v-model='project_filter_input' placeholder='프로젝트 이름 검색')
            .p-2
              b-form-checkbox-group(v-model='checked_projects')
                b-form-checkbox(v-for='doc in $store.state.documents' :key='doc.id' :value='doc.id' v-show='(doc.name || "").includes(project_filter_input)')
                  span.col-150.d-inline-block {{doc.name}}
          div.w-25.pl-1
            button.btn.btn-default.btn-block.bg-white.border.btn-sm(type='button' @click='project_filter_deselect_all') 선택 해제
            button.btn.btn-default.btn-block.bg-white.border.btn-sm(type='button' @click='project_filter_push_include') 대상자 포함
            button.btn.btn-default.btn-block.bg-white.border.btn-sm(type='button' @click='project_filter_push_exclude') 대상자 제외
            button.btn.btn-default.btn-block.bg-white.border.btn-sm(type='button' @click='project_filter_push_search') 검색어 추가
            div(v-for='pid in checked_projects')
              input.form-control.form-control-sm(v-model='project_filter_search_input[pid]' :placeholder='$store.state.documents_by_id[pid].name' v-b-tooltip :title='$store.state.documents_by_id[pid].name + ` 검색어`')
              //- label
                strong.col-150 {{$store.state.documents_by_id[pid].name}}


      //- strong 프로젝트
    .row.justify-content-center.align-items-center(v-if='$store.state.documents.length === 0')
      .col-6.async(:class='{done:(done)}')
        .mt-4
        h5.title 연락처가 아직 없어요.
        br
        p 고객정보를 입력하시면 여기에 자동으로 묶음 표시됩니다.

        div(style='padding-top: 150px')
    //- pre {{project_filter_search_input}}
    //- pre {{groups}}
    //- pre {{has_document_id}}
    //- pre {{property.contact_config}}

    div.pt-2(style='padding-left: calc(33vw - 450px);')
      div.d-flex.table-project.border-top.position-sticky.bg-white.border-bottom(style='width: max-content; top:0px; z-index: 10; margin-bottom: -1px')
        div.th-check(@click.prevent.stop='select_all')
          label.m-0(@click.prevent.stop='select_all')
            b-form-checkbox.rounded-0(:checked='selected_all' value='true' unchecked-value='false')

        div.border-left.header.handle.th.th-group(style='width: 200px' :id='`opt_field_table_GROUP_ID`' @click.stop='$root.$emit("bv::hide::popover"); $root.$emit("bv::show::popover", `opt_field_table_GROUP_ID`)') 그룹 ID
          small.float-right(style='padding-top: 1px') 조건 {{groups.length}}개
          b-popover(:ref='`opt_field_table_GROUP_ID`' :target='`opt_field_table_GROUP_ID`' triggers='click' no-fade placement='bottom' custom-class='popover-dropdown')
            div
              span.dropdown-label.px-2 묶음 기준
              button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='update_groups_toggle("@고객아이디")')
                span 고객 아이디
                b-icon-check(v-show='groups_by_format[`@고객아이디`]')
              button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='update_groups_toggle("@고객이름")')
                span 고객 이름
                b-icon-check(v-show='groups_by_format[`@고객이름`]')
              button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='update_groups_toggle("@고객연락처")')
                span 고객 전화번호
                b-icon-check(v-show='groups_by_format[`@고객연락처`]')
              button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='update_groups_toggle("@고객이메일")')
                span 고객 이메일주소
                b-icon-check(v-show='groups_by_format[`@고객이메일`]')
          //- custom fields
          //- draggable(:class='{dragging: drag}' v-model='document.config.cols' handle='.handle' v-bind='drag_options' @start='drag = true' @end='drag_end')
          //-   transition-group.d-flex(type='transition' :name="!drag ? 'flip-list' : null")
        div.border-left.header.handle.th(style='width: 200px' v-show='!groups_by_format["@고객아이디"]') 아이디
        div.border-left.header.handle.th(style='width: 200px' v-show='!groups_by_format["@고객이름"]') 이름
        div.border-left.header.handle.th(style='width: 200px' v-show='!groups_by_format["@고객연락처"]') 전화번호
        div.border-left.header.handle.th(style='width: 200px' v-show='!groups_by_format["@고객이메일"]') 이메일주소
        div.border-left.header.handle.th(style='width: 200px' v-for='document_id in has_document_id') {{($store.state.documents_by_id[document_id] && $store.state.documents_by_id[document_id].name || '')}}
        div.th.bg-white(style='width: 200px; margin: -1px; 0px;')
        //- div.th.border-left.header(style='width: 30px' @click='add_document_col')
          b-icon-plus

      table.table.table-project.border-bottom(v-if='cols_ready' style='width: max-content')
        tbody
          tr.hover(:key='row.id' v-for='row in rows' :class='{"row-selected": row.is_selected}')
            //-  @click.keydown.meta.prevent.stop='open_record_new(row)' @click.keydown.ctrl.prevent.stop='open_record_new(row)' @click.exact='open_record(row)'
            td.td-check(@click.prevent.stop='select_row(row)')
              //- input(type='checkbox')
              b-form-checkbox(v-model='row.is_selected' value='true' unchecked-value='false' @click.prevent.stop='')
            td.td.border-left.td-group(style='width: 200px;')
              span.d-block.col-150(v-b-tooltip :title='row.group_id') {{row.group_id}}
            //- td.td.border-left(v-for='col in document.config.cols' :style='{width: (col.width || 200)+"px", maxWidth: (col.width || 200)+"px"}')
              span {{row.json[col.key]}}
            td.td.border-left(style='width: 200px' v-show='!groups_by_format["@고객아이디"]')
              span.d-block.col-150 {{row.rows[0].json.__0 | first }}
            td.td.border-left(style='width: 200px' v-show='!groups_by_format["@고객이름"]')
              span.d-block.col-150 {{row.rows[0].json.__3 | first }}
            td.td.border-left(style='width: 200px' v-show='!groups_by_format["@고객연락처"]')
              span.d-block.col-150 {{row.rows[0].json.__1 | first }}
            td.td.border-left(style='width: 200px' v-show='!groups_by_format["@고객이메일"]')
              span.d-block.col-150(v-b-tooltip :title='row.rows[0].json.__2') {{row.rows[0].json.__2| first }}
            td.td.border-left(style='width: 200px' v-for='document_id in has_document_id')
              .p-1.shadow-sm.border.rounded.card-project-record.mb-1(v-for='subrow of row.rows' v-if='subrow.document_id == document_id'
                @click.exact='open_record(subrow)'
              )
                //- span {{subrow.document_id}}
                //- span.d-block.opacity-50 {{subrow.customer_text}}
                span.d-block.col-150(v-if='subrow.field_text && subrow.field_text.length') {{subrow.preview_text}} {{subrow.field_text}}
                span.d-block.col-150.opacity-50(v-else) 비어있음

            //- td.td.border-left(style='width: 30px')

    modal(name='record' width='1130px' :height='`auto`' :scrollable='true' draggable='.header-draggable'
      @before-close='record_before_close'
      transition='none'
      overlay-transition='none'
    )
      //- :clickToClose='false'
      button.btn.btn-default.float-right.text-muted.rounded-0.bg-light(type='button' @click='$modal.hide("record")' style='font-size: 1.5rem'): b-icon-x
      //- div(style='height: 650px')
      div
        document-record(
          :property='property'
          :document='document'
          :document_record_id='document_record_id'
          @loaded='record_did_loaded'
          @updated='record_did_updated'
          @closing='$modal.hide("record")'
        )
        //- :record_id='record_id'
        //- :tags='tags'
        //- @tag_updated='record_tag_did_updated'

</template>

<script>

import ExcelImport from '@/components/ExcelImport'
import DocumentRecord from '@/components/DocumentRecord'
import ContactSearchFilter from "@/components/ContactSearchFilter";

import { customAlphabet } from 'nanoid'
const nanoid = customAlphabet('123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz', 5)

import draggable from 'vuedraggable'
const xlsx = require('xlsx')
const moment = require('moment')

export default {
  name: 'index',
  props: ['document_id', 'document_name', 'saved_filter_id', 'document_record_id'],
  components: {
    ExcelImport, DocumentRecord, ContactSearchFilter,
    draggable,
  },
  computed: {
    session() {
      return this.$store.state.session
    },
    property() {
      return this.$store.state.property
    },
  },
  watch: {
    '$store.state.documents'() {
      this.load()
    },
    'document_id'() {
      this.rows_ready = false
      this.load()
    },
    'document_record_id'() {
      this.open_modal()
    },
  },
  mounted() {
    this.load()
    // setTimeout(() => {
    //   this.open_filter_modal()
    // }, 300);
  },
  filters: {
    first(e) {
      return e ? e[0] : ''
    },
  },
  data() {
    return {
      done: false,
      loading: false,
      adding: false,
      document: {},
      rows: [],
      rows_count: 0,
      rows_ready: false,
      rows_selected_count: 0,
      fills: Array.from('00000000000000000000000'),

      record_loaded: false,

      dropdown_group_record_active: false,

      filters: [],
      selected_filter: null,

      saved_filter: {},
      form_filter: {
        name: '',
        is_shared: 'N',
      },
      filter_changed: false,

      tags: [],
      tags_by_record_id: {},
      tags_count: null,

      min_height: '90vh',
      should_open_new_window: false,
      selected_all: false,

      sort: 'DESC',
      sortby: 'created_at',
      cols: [],
      cols_by_key: {},
      cols_by_key_loaded: false,

      tag_selected_action: '',

      fullpage_record_id: null,
      scroll_stop: false,

      cols_ready: false,
      cols_primary: [],

      groups: [],
      groups_by_format: {},

      // document_record_id: null,

      drag: false,

      drag_options: {
        animation: 200,
        ghostClass: 'ghost',
      },
      cols_ordered: [],

      new_filter_name: '',
      current_filter: {},

      has_document_id: [],

      checked_projects: [],
      project_filter_input: '',
      project_filter_search_input: {},
    }
  },
  methods: {
    project_filter_deselect_all() {
      this.checked_projects = []
      // if (this.checked_projects.length === this.$store.state.documents.length) {
      //   this.checked_projects = []
      // } else {
      //   this.checked_projects = [].concat(this.$store.state.documents.map(e => e.id))
      // }
    },
    project_filter_push_include() {
      if (!this.checked_projects.length) return
      let added;
      for (const p of this.checked_projects) {
        const d = this.$store.state.documents_by_id[p]
        const add = {
          prekey: 'search.project',
          key: 'search.project.include',
          label: d.name,
          document_id: d.id,
          document_name: d.name,
          ts: Date.now(),
        }
        if(!added) {
          added = add
        } else {
          if (!added.sub_or) added.sub_or = []
          add.parent_ts = added.ts
          added.sub_or.push(add)
        }
      }
      this.filters.push(added)
      this.persist_local_filter()
      this.load()
      this.checked_projects = []
    },
    project_filter_push_exclude() {
      if (!this.checked_projects.length) return
      let added;
      for (const p of this.checked_projects) {
        const d = this.$store.state.documents_by_id[p]
        const add = {
          prekey: 'search.project',
          key: 'search.project.exclude',
          label: d.name,
          document_id: d.id,
          document_name: d.name,
          ts: Date.now(),
        }
        if(!added) {
          added = add
        } else {
          if (!added.sub_or) added.sub_or = []
          add.parent_ts = added.ts
          added.sub_or.push(add)
        }
      }
      this.filters.push(added)
      this.persist_local_filter()
      this.load()
      this.checked_projects = []
    },
    project_filter_push_search() {
      let added;
      const ids = Object.keys(this.project_filter_search_input)
      for (const pid of ids) {
        if (!this.project_filter_search_input[pid] || this.project_filter_search_input[pid].length === 0) continue
        const value = this.project_filter_search_input[pid]
        const d = this.$store.state.documents_by_id[pid]
        const add = {
          prekey: 'search.project',
          key: 'search.project.value',
          label: d.name,
          document_id: d.id,
          document_name: d.name,
          value,
          ts: Date.now(),
        }
        if(!added) {
          added = add
        } else {
          if (!added.sub_or) added.sub_or = []
          add.parent_ts = added.ts
          added.sub_or.push(add)
        }
      }
      if (added) {
        this.filters.push(added)
        this.persist_local_filter()
        this.load()
      }
    },
    update_groups_toggle(format) {
      const exists = this.groups.filter(e => e.format == format)
      if (exists.length) {
        this.groups = this.groups.filter(e => e.format != format)
      } else {
        this.groups.push({
          format,
        })
      }
      this.persist_local_filter()
      this.load()
    },
    clear_current_filter() {
      this.$root.$emit('bv::hide::popover')
      setTimeout(() => {
        this.selected_filter = null
        this.current_filter = {}
        this.filters = []
        this.persist_local_filter()
        this.load()
      }, 100);
      return
    },
    delete_current_filter() {
      if (!confirm('정말로 필터를 삭제하시겠습니까?')) return false

      this.property.contact_config.filters = this.property.contact_config.filters.filter(e => {
        return e.ts != this.current_filter.ts
      })
      this.persist_filter()
    },
    save_current_filter() {
      this.persist_filter()
      this.$root.$emit('bv::hide::popover')
    },
    open_filter(f) {
      this.current_filter = f
      this.filters = f.filters

      this.persist_local_filter()
      this.load()
    },
    async persist_filter() {
      this.loading = true
      const r = await this.$http.put(`/v1/property/${this.property.id}/contact_config`, {
        contact_config: this.property.contact_config,
      })
      if (r?.data?.message != 'ok') throw new Error('필터 저장 실패')
      setTimeout(() => { this.loading = false }, 100)
    },
    async save_filter() {
      this.loading = true
      try {
        if (!this.property.contact_config.filters) {
          this.property.contact_config.filters = []
        }
        const current_filter = {
          name: this.new_filter_name,
          filters: this.filters,
          ts: Date.now(),
        }
        this.property.contact_config.filters.push(current_filter)
        this.new_filter_name = ''
        this.current_filter = current_filter

        this.persist_filter()
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      this.loading = false
    },
    persist_local_filter() {
      const prev = '' + window.sessionStorage[`noitaler.${this.property.id}.contact.filter`]
      const next = JSON.stringify(Object.assign({}, this.current_filter, {
        filters: this.filters,
        groups: this.groups,
      }))
      if (prev != next) {
        this.filter_changed = true
        window.sessionStorage[`noitaler.${this.property.id}.contact.filter`] = next
      }
    },
    open_selected_filter_modal(item) {
      this.selected_filter = item
      this.$modal.show('filter')
    },
    or_filter(item) {
      const subitem = Object.assign({}, {
        sub_or: undefined,
        parent_ts: item.ts,
        ts: Date.now(),
      })
      this.filters = this.filters.filter(e => {
        if (+e.ts == +item.ts) {
          if (!item.sub_or) item.sub_or = []

          item.sub_or.push(subitem)
        }
        return e
      })
      this.open_selected_filter_modal(subitem)
      this.persist_local_filter()
    },
    open_filter_modal() {
      this.selected_filter = null
      this.$modal.show('filter')
    },
    clear_filter(item) {
      if (item.parent_ts) {
        this.filters = this.filters.filter(e => {
          if (+e.ts != +item.parent_ts) return true

          if (e.sub_or && e.sub_or.length) {
            e.sub_or = e.sub_or.filter(l => {
              return +l.ts != item.ts
            })
          }
          return true
        })
      } else {
        this.filters = this.filters.filter(e => {
          return +e.ts != +item.ts
        })
      }
      this.selected_filter = null
      if (this.filters.length == 0) {
        this.current_filter = {}
      }
      this.persist_local_filter()
      this.load()
      return
    },
    filter_did_updated(item) {
      console.log('filter_did_updated', item)
      if (item === 'dismiss') {
        // dismiss
        return this.$modal.hide('filter')
      }
      if (item === 'clear') {
        this.$modal.hide('filter')
        return this.clear_filter(this.selected_filter)
      }
      this.$modal.hide('filter')

      if (item.parent_ts) {
        this.filters = this.filters.map(e => {
          if (+e.ts == +item.parent_ts) {
            console.log('found ! ', e.sub_or)
            if(e.sub_or?.length) {
              e.sub_or = e.sub_or.map(l => {
                console.log('check ? ', l.ts, item.ts)
                if (+l.ts == +item.ts) {
                  console.log('check ! ')
                  return item
                }
                return l
              })
            }
          }
          return e
        })
      } else {
        const need_update = this.filters.filter(e => e.ts == item.ts).length
        if (need_update) {
          this.filters = this.filters.map(e => {
            if (e.ts == item.ts) {
              return item
            }
            return e
          })
        } else {
          this.filters.push(item)
        }
      }

      this.persist_local_filter()
      this.load()
    },
    export_file(output_format) {
      const wb = xlsx.utils.book_new()

      // const ws = xlsx.utils.table_to_sheet(this.$refs.table)

      // const ws = xlsx.utils.json_to_sheet(this.rows.map(e => e.json), {header:this.document.config.cols.map(e => e.key), skipHeader:false});
      // const pre = ['ID', 'Created At', 'Updated At']
      const pre = ['아이디', '연락처', '이메일', '이름']
      const ws = xlsx.utils.aoa_to_sheet(
        [
          pre,
          ...this.rows.map(row => {
            return [
              row.rows[0].json.__0,
              row.rows[0].json.__1,
              row.rows[0].json.__2,
              row.rows[0].json.__3,
            ]
          }),
        ],
      )

      xlsx.utils.book_append_sheet(wb, ws, ['연락처', this.current_filter.name].join('-'))
      if (output_format == 'csv') {
        xlsx.writeFile(wb, `noitaler-export-${moment().format('YYYY.MM.DD') + '-' + Date.now()}.csv`)
        return
      }
      if (output_format == 'xlsx') {
        xlsx.writeFile(wb, `noitaler-export-${moment().format('YYYY.MM.DD') + '-' + Date.now()}.xlsx`)
        return
      }
      if (output_format == 'html') {
        xlsx.writeFile(wb, `noitaler-export-${moment().format('YYYY.MM.DD') + '-' + Date.now()}.html`, {
          editable: true,
          header: '<html><head><style>* { font-size: 14px; font-family: sans-serif } </style></head><body>',
        })
        return
      }
    },
    async drag_end() {
      try {
        this.drag = false
        this.save_document_col()
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    blur_popover() {
      // console.log('blur_popover')
      this.$root.$emit("bv::hide::popover")
    },
    async update_format_document_col(col, value) {
      try {
        if (value == '@고객아이디') {
          const exists = this.document.config.cols.filter(e => e.format == value)
          if (exists.length) {
            throw new Error(`[${exists[0].label}] 항목이 이미 [@고객아이디]로 지정되어 있습니다.`)
          }
        }
        if (value == '@고객연락처') {
          const exists = this.document.config.cols.filter(e => e.format == value)
          if (exists.length) {
            throw new Error(`[${exists[0].label}] 항목이 이미 [@고객전화번호]로 지정되어 있습니다.`)
          }
        }
        if (value == '@고객이메일') {
          const exists = this.document.config.cols.filter(e => e.format == value)
          if (exists.length) {
            throw new Error(`[${exists[0].label}] 항목이 이미 [@고객이메일]로 지정되어 있습니다.`)
          }
        }
        if (value == '@고객이름') {
          const exists = this.document.config.cols.filter(e => e.format == value)
          if (exists.length) {
            throw new Error(`[${exists[0].label}] 항목이 이미 [@고객이름]으로 지정되어 있습니다.`)
          }
        }
        col.format = value
        this.save_document_col()

      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async save_document_col() {
      this.loading = true
      try {
        const document = Object.assign({}, this.document, {
          config: Object.assign({}, this.document.config, {
            cols: this.document.config.cols,
          })
        })
        const r = await this.$http.put(`/v1/property/${this.property.id}/views/documents/${this.document.id}`, document)
        if (r?.data?.message != 'ok') throw new Error('목록 항목 저장 실패')

        await this.$store.dispatch('documents', this.property.id)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      setTimeout(() => { this.loading = false }, 300)
    },
    add_document_col() {
      const key = 'd' + nanoid()

      this.document.config.last_col_id++
      this.document.config.cols.push({
        key,
        label: 'Property',
        format: 'text',
      })
      this.save_document_col()
      setTimeout(() => {
        this.$root.$emit('bv::show::popover', `opt_field_table_${key}`)
        this.$refs[`opt_field_input_table_${key}`][0].focus()
      }, 300)
    },
    delete_document_col(col) {
      if (!confirm(`[${col.label}] 항목을 삭제하시겠습니까?`)) return false
      this.document.config.cols = this.document.config.cols.filter(e => {
        return e.key != col.key
      })
      this.save_document_col()
    },

    async create() {
      this.loading = true
      try {
        const r = await this.$http.post(`/v1/property/${this.property.id}/views/documents/${this.document.id}/records`, {
          row: {},
        })
        const document_record_id = r.data.row_id
        this.$router.push({
          name: 'contact.view.record',
          params: {
            document_record_id,
          }
        })
        this.load()
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
        setTimeout(() => { this.loading = false }, 300)
      }
    },
    async open_modal() {
      if (this.document_record_id) {
        if (!this.document || !this.document.id) {
          try {
            this.loading = true
            const r = await this.$http.get(`/v1/property/${this.property.id}/views/documents/records/row/${this.document_record_id}/find_document_id`)
            const document_id = r?.data?.row?.document_id
            if (!document_id) throw new Error('해당 내역의 프로젝트를 찾을 수 없습니다.')
            this.document = this.$store.state.documents_by_id[document_id]
          } catch (error) {
            this.$modal.show('dialog', {
              title: '알림',
              text: error.message,
            })
          }
        }
        this.$modal.show('record')
      } else {
        this.$modal.hide('record')
      }
    },
    record_did_loaded() {
      this.record_loaded = true
      // setTimeout(() => { this.loading = false }, 300)
    },
    record_did_updated() {
      console.log('reload from record_did_updated')
      this.load()
    },
    record_before_close(event) {
      // event.cancel()
      if (this.document_record_id) {
        // this.document_record_id = null
        // this.open_modal()
        this.$router.push({
          name: 'contact.view',
        })
        setTimeout(() => {
          this.update_title()
        }, 100);
      }
    },
    open_record_new(row) {
      const r = this.$router.resolve({
        name: 'contact.view.record',
        params: {
          document_record_id: row.id,
        }
      })
      window.open(r.href, '_blank')
      return false
    },
    open_record(row) {
      this.document = this.$store.state.documents_by_id[row.document_id]
      this.$router.push({
        name: 'contact.view.record',
        params: {
          document_record_id: row.id,
        }
      })
    },
    select_all() {

    },
    async save_document_name() {
      try {
        this.loading = true
        const r = await this.$http.put(`/v1/property/${this.property.id}/views/documents/${this.document.id}`, this.document)
        if (r?.data?.message != 'ok') throw new Error('프로젝트 저장 실패')

        await this.$store.dispatch('documents', this.property.id)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      setTimeout(() => {
        this.loading = false
      }, 300);
    },
    async delete_document() {
      try {
        if(!confirm('정말로 삭제하시나요?')) return true

        const r = await this.$http.delete(`/v1/property/${this.property.id}/views/documents/${this.document.id}`)
        if (r?.data?.message != 'ok') throw new Error('프로젝트 삭제 실패')

        await this.$store.dispatch('documents', this.property.id)

        this.$modal.show('dialog', {
          title: '알림',
          text: '삭제했습니다.',
        })
        this.$router.push({
          name: 'layout',
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    did_saved_excel() {
      this.$modal.hide('excel')
      this.$modal.show('dialog', {
        title: '알림',
        text: '추가했습니다.',
      })
      this.load()
    },
    open_db_modal() {
      this.$modal.show('dialog', {
        title: '알림',
        text: '준비중입니다.',
      })
    },
    update_title() {
      const text = []
      text.push(`연락처`)
      if (!this.document_record_id) {
        document.title = text.join(' ')
      }
    },
    async load() {
      if (!this.$store.state.documents || this.$store.state.documents.length == 0) return
      if (!this.$store.state.property) return

      // this.document = Object.assign({}, this.$store.state.documents_by_id[this.document_id])
      // if (!this.document.id) {
      //   this.$modal.show('dialog', {
      //     title: '알림',
      //     text: '해당 프로젝트를 찾지 못했습니다.',
      //   })
      //   return
      // }
      try {
        console.log('saved_filter_id', this.saved_filter_id)
        console.log('saved_filter', this.saved_filter, this.saved_filter.id)

        // load local filter
        const saved_filter = window?.sessionStorage[`noitaler.${this.property.id}.contact.filter`]
        console.log(':saved_filter', saved_filter)
        if (saved_filter) {
          try {
            const current_filter = JSON.parse(saved_filter)
            // console.log(current_filter)
            this.current_filter = current_filter
            this.filters = current_filter.filters
            this.groups = current_filter.groups
            // NOTE: groups by format later
          } catch (error) {
            console.log('failed to parse', saved_filter)
          }
        } else {
          this.filters = []
          this.current_filter = {}
        }

        // if (!this.saved_filter.id) {
        //   this.$emit('filter_updated', {})
        // }

        // if (!this.sort_loaded) {
        //   const sort = window?.sessionStorage[`noitaler.${this.property.id}.customer.${this.document.id}.sort`]
        //   if (sort) {
        //     try {
        //       const opt = JSON.parse(sort)
        //       this.sort = opt.sort
        //       this.sortby = opt.sortby
        //       this.sort_loaded = true
        //     } catch (error) {
        //       console.log('failed to parse', sort)
        //     }
        //   }
        // }

        console.log('groups init', this.groups)
        if (this.groups.length === 0) {
          this.groups = [
            // {
            //   format: '@고객아이디',
            // },
            {
              format: '@고객이름',
            },
            // {
            //   format: '@고객연락처',
            // },
            // {
            //   format: '@고객이메일',
            // },
          ]
        }
        this.groups_by_format = Object.assign({}, ...this.groups.map(e => {
          return { [e.format]: e }
        }))

        this.loading = true
        const r = await this.$http.get(`/v1/property/${this.property.id}/views/documents/contacts/records`, {
          params: {
            groups: JSON.stringify(this.groups),
            filters: JSON.stringify(this.filters),
            sort: this.sort,
            sortby: this.sortby,
          }
        })
        const m = r?.data?.message || ''
        if (m != 'ok') {
          console.log('>>>>>>>', m)
          if (m.includes('(filter)')) {
            console.log('>>>>>>>in')
            // reset filter
            window.sessionStorage[`noitaler.${this.property.id}.contact.filter`] = undefined
          }
          // window.sessionStorage[`noitaler.${this.property.id}.customer.${this.document.id}.filter`] = undefined
          throw new Error('불러오기 실패: '+ r?.data?.message)
        }
        const has_document_id = new Set
        this.rows = r.data.rows_ids.map(e => {
          const ids = r.data.rows_indexed[e].map(e => e.document_id)
          ids.forEach(e => has_document_id.add(e))
          const rows = r.data.rows_indexed[e]
          // const rows_by_document = {}
          // for (const row of rows) {
          //   rows_by_document
          // }
          return {
            group_id: e,
            document_ids: ids,
            rows,
            is_selected: false,
          }
        })
        this.has_document_id = [...has_document_id]
        this.rows_count = r.data.rows_count
        this.rows_selected_count = 0
        this.rows_ready = true

        // this.cols = [
        //   {
        //     key: 'group_id',
        //     format: 'group',
        //   },
        // ]
        this.cols_ready = true
        const cols_by_key = {}

        // this.document.config.cols - this.document.config.cols.map(e => {
        //   if (!e.width) e.width = 200
        //   return e
        // })

        // for (const col of this.document.config.cols) {
        //   if (!this.cols_by_key_loaded) {
        //     cols_by_key[col.key] = col
        //   }
        // }
        // if (!this.cols_by_key_loaded) {
        //   this.cols_by_key = cols_by_key
        //   this.cols_by_key_loaded = true
        // }

        this.saving_text = '저장'
        this.done = true

        // this.document_record_id = this.document_record_id
        this.open_modal()

        // this.open_filter_modal()
        this.update_title()
      } catch (error) {
        console.log(error)
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      this.loading = false
    },
  },
}
</script>
