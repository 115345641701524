<style lang="sass" scoped>
.scroll
  height: 300px
  // min-height: 300px
  overflow: scroll
  // max-height: 300px
.item
  border-left: solid 2px #fff
  font-size: 14px
  font-weight: 500
  &.active
    border-left: solid 2px #007bff
  // &:hover
  //   border-left: solid 2px #007bff
  //   background-color: #fafafa
  //   cursor: pointer
  &.selected
    border-left: solid 2px #007bff
    background-color: #fafafa
.query
  font-size: 18px
  padding: 2rem 1rem
</style>
<template lang="pug">
div
  input.form-control.query(
    type='text' v-model='q' ref='input'
    :placeholder='placeholder'
    @input='q = $event.target.value'
    @keydown.down.prevent='select_down()'
    @keydown.up.prevent='select_up()'
    @keyup.enter.prevent='select_enter()'
    @keyup='do_filter'
  )
  .scroll.py-2(ref='items')
    //- pre {{selected}}
    .item.px-3.py-2(v-for='(item, i) in filtered_items' @click='select(item)' :class="{selected:(selected_index===i)}" @mouseover='selected_index = i')
      span(v-if='item.key == "search.text.value"')
        span.mr-2(style='opacity: 0.6') "{{item.value}}"
        span 검색
      span(v-else-if='item.key == "search.customer.value"')
        span.mr-2(style='opacity: 0.6') "{{item.value}}"
        span 고객 찾기
      span(v-else-if='item.key == "search.field.value"')
        span.mr-2(style='opacity: 0.6') "{{item.col.label}}: {{item.value}}"
        span 검색
      span(v-else-if='item.key == "search.field.value_not_contain"')
        span.mr-2(style='opacity: 0.6') "{{item.col.label}}: {{item.value}}"
        span 포함하지 않음
      span(v-else-if='item.key == "search.field.value_is_empty"')
        span.mr-2(style='opacity: 0.6') {{item.col.label}}
        span 비어있음
      span(v-else-if='item.key == "search.field.value_isnt_empty"')
        span.mr-2(style='opacity: 0.6') {{item.col.label}}
        span 비어있지 않음
      span(v-else-if='item.key == "search.row.in_ids"')
        span.mr-2(style='opacity: 0.6') @고객아이디
        span 일치하는 고객을 포함
      span(v-else-if='item.key == "search.row.not_in_ids"')
        span.mr-2(style='opacity: 0.6') @고객아이디
        span 일치하는 고객을 제외
      span(v-else-if='item.key == "search.project.include"')
        span.mr-2(style='opacity: 0.6') "{{item.document_name}}"
        span 대상자 포함
      span(v-else-if='item.key == "search.project.exclude"')
        span.mr-2(style='opacity: 0.6') "{{item.document_name}}"
        span 대상자 제외
      span(v-else-if='item.key == "search.project.value"')
        span.mr-2(style='opacity: 0.6') "{{item.value}}"
        span 검색
      span(v-else-if='item.prekey == "search.project"')
        span.mr-2(style='opacity: 0.6') "{{item.document_name}}"
        span 프로젝트 검색
      span(v-else-if='item.prekey == "search.field"') {{item.label}}
        span.float-right.text-muted(v-if='item.document_name') {{item.document_name}}
        //- document_name
        //- span.mr-2(style='opacity: 0.6') @고객아이디
        //- span 일치하는 고객을 제외
      span(v-else-if='item.key == "clear"')
        span 필터 지우기
      span(v-else) {{item.label}}
        //- span(v-else) {{item}}
        span.float-right.text-muted(v-if='item.description') {{item.description}}
</template>

<script>
import * as hangul from 'hangul-js'
import {findIndex} from 'lodash'

export default {
  name: "SearchFilter",
  props: ['documents', 'filter-selected', 'default-selected'],
  data() {
    return {
      q: '',
      placeholder: '검색 조건을 선택해주세요.',
      items: [],
      filtered_items: [],

      selected: {},
      selected_index: 0,

      prekey_has_delay: false
    }
  },
  mounted() {
    if (this.defaultSelected && this.defaultSelected.key) {
      this.selected = this.defaultSelected
      if (this.selected.key == 'search.text.value') this.selected.key == 'search.text'
      if (this.selected.key == 'search.customer.value') this.selected.key == 'search.customer'
      this.q = this.defaultSelected.value

      this.load()
      this.do_filter()
      if (this.selected) {
        const idx = findIndex(this.filtered_items, (o) => { return o.key == this.selected.key })
        console.log({idx}, this.filtered_items, this.selected.key)
        if (idx) this.selected_index = idx
      }
    } else {
      this.load()
    }
  },
  methods: {
    select(item) {
      if (this.defaultSelected?.parent_ts) {
        item.parent_ts = this.defaultSelected.parent_ts
        item.ts = this.defaultSelected.ts
      } else {
        item.ts = Date.now()
      }
      console.log('select ', item)
      if (item.key == 'clear') {
        this.$emit('updated', 'clear')
        return
      }
      if (this.defaultSelected && this.defaultSelected.key) {
        // nothing changed
        // if (item.value == this.defaultSelected.value) {
        //   this.$emit('updated', 'dismiss')
        //   return
        // }
        item.ts = this.defaultSelected.ts
        this.$emit('updated', item)
      }
      if (item.key == 'search.text.value') {
        this.$emit('updated', item)
        return
      }
      if (item.key == 'search.customer.value') {
        this.$emit('updated', item)
        return
      }
      if (item.key == 'search.field.value') {
        this.$emit('updated', item)
        return
      }
      if (item.key == 'search.field.value_not_contain') {
        this.$emit('updated', item)
        return
      }
      if (item.key == 'search.field.value_is_empty') {
        this.$emit('updated', item)
        return
      }
      if (item.key == 'search.field.value_isnt_empty') {
        this.$emit('updated', item)
        return
      }
      if (item.key == 'search.project.include') {
        this.$emit('updated', item)
        return
      }
      if (item.key == 'search.project.exclude') {
        this.$emit('updated', item)
        return
      }
      if (item.key == 'search.project.value') {
        this.$emit('updated', item)
        return
      }

      this.selected = item
      console.log('delay?', item)
      if (item.prekey && !item.key) {
        console.log('delay')
        this.prekey_has_delay = true
        setTimeout(() => {
          this.prekey_has_delay = false
        }, 300);
      }
      this.load()
    },
    select_down() {
      this.selected_index = Math.min(this.filtered_items.length-1, this.selected_index+1)

      const items = this.$refs.items.querySelectorAll('.item')
      if (items[this.selected_index]) {
        items[this.selected_index].scrollIntoView({block: 'nearest'})
      }
    },
    select_up() {
      this.selected_index = Math.max(0, this.selected_index-1)
      const items = this.$refs.items.querySelectorAll('.item')
      if (items[this.selected_index]) {
        items[this.selected_index].scrollIntoView({block: 'nearest'})
      }
    },
    select_enter() {
      if (this.prekey_has_delay) {
        console.log('stopped from delay')
        return false
      }
      if (this.filtered_items.length === 1) {
        return this.select(this.filtered_items[0])
      }
      const item = this.filtered_items[this.selected_index]
      if (item) {
        this.select(item)
      }
    },
    do_filter() {
      let q = String(this.q).trim()
      if (q.length === 0) {
        this.filtered_items = this.items
        if (this.defaultSelected) {
          if (this.selected.prekey == 'search.text') {
            this.filtered_items = [{
              key: 'clear',
            }]
          }
          if (this.selected.prekey == 'search.customer') {
            this.filtered_items = [{
              key: 'clear',
            }]
          }
        }
        if (this.selected.prekey == 'search.project') {
          this.filtered_items = [
            {
              prekey: 'search.project',
              key: 'search.project.include',
              label: String(this.selected.document_name || ''),
              document_id: this.selected.document_id,
              document_name: this.selected.document_name,
            },
            {
              prekey: 'search.project',
              key: 'search.project.exclude',
              label: String(this.selected.document_name || ''),
              document_id: this.selected.document_id,
              document_name: this.selected.document_name,
            },
          ]
        }
        if (this.selected.prekey == 'search.field') {
          console.log('>>', this.selected.col)
          if (this.selected.col.format == 'check') {
            this.filtered_items = [
              {
                key: 'search.field.value',
                prekey: 'search.field',
                value: 'Y',
                label: 'Y',
                col: this.selected.col,
              },
              {
                key: 'search.field.value',
                prekey: 'search.field',
                value: 'N',
                label: 'N',
                col: this.selected.col,
              },
              {
                key: 'search.field.value_is_empty',
                prekey: 'search.field',
                value: q,
                col: this.selected.col,
              },
              {
                key: 'search.field.value_isnt_empty',
                prekey: 'search.field',
                value: q,
                col: this.selected.col,
              },
            ]
          } else {
            this.filtered_items = [
              {
                key: 'search.field.value_is_empty',
                prekey: 'search.field',
                value: q,
                col: this.selected.col,
              },
              {
                key: 'search.field.value_isnt_empty',
                prekey: 'search.field',
                value: q,
                col: this.selected.col,
              },
            ]
          }


        }
        return
      }

      this.filtered_items = [].concat(this.items).filter(e => {
        let matched_label = false
        if (q.length >= 1) {
          const v = hangul.search(e.label, q)
          if (v >= 0) {
            matched_label = true
          }
          // matched_label = String(e.label).match(new RegExp(q, 'im'))
        }
        // if (q.length >= 2) {
        //   matched_email = e.email.replace(/\.|@/g, '').match(new RegExp(q, 'im'))
        // }
        // if (q.length >= 1) {
        //   matched_name = e.name.replace(/\s/g, '').match(new RegExp(q, 'im'))
        // }
        return matched_label
      })

      // add itself
      console.log('>>>>>', this.selected.prekey)
      if (this.selected.prekey == 'search.text') {
        this.filtered_items.push({
          key: 'search.text.value',
          prekey: 'search.text',
          value: q,
        })
      } else if (this.selected.prekey == 'search.customer') {
        this.filtered_items.push({
          key: 'search.customer.value',
          prekey: 'search.customer',
          value: q,
        })
      } else if (this.selected.prekey == 'search.field') {
        if (q) {
          this.filtered_items.push({
            key: 'search.field.value',
            prekey: 'search.field',
            value: q,
            col: this.selected.col,
          })
          this.filtered_items.push({
            key: 'search.field.value_not_contain',
            prekey: 'search.field',
            value: q,
            col: this.selected.col,
          })

        }

        // this.$set(this.filtered_items, 'z', 1)
        // console.log('filtered_items', this.filtered_items)
        // this.$set(this.filtered_items, 'a', 1)
      } else if (this.selected.prekey == 'search.row') {
        this.filtered_items = [{
          key: 'search.row.in_ids',
          prekey: 'search.row',
          value: this.selected.value,
          col: this.selected.col,
        },
        {
          key: 'search.row.not_in_ids',
          prekey: 'search.row',
          value: this.selected.value,
          col: this.selected.col,
        }]
      } else if (this.selected.prekey == 'search.project') {
        this.filtered_items = [
          // {
          //   key: 'search.project.in_ids',
          //   prekey: 'search.project',
          //   value: this.selected.value,
          //   document_id: this.selected.document_id,
          //   document_name: this.selected.document_name,
          // },
          // {
          //   key: 'search.project.in_ids',
          //   prekey: 'search.project',
          //   value: this.selected.value,
          //   document_id: this.selected.document_id,
          //   document_name: this.selected.document_name,
          // },
          {
            key: 'search.project.value',
            prekey: 'search.project',
            value: q,
            document_id: this.selected.document_id,
            document_name: this.selected.document_name,
          },
        ]
      } else {
        // 기본 검색
        this.filtered_items.push({
          key: 'search.text.value',
          prekey: 'search.text',
          value: q,
        })
        this.filtered_items.push({
          key: 'search.customer.value',
          prekey: 'search.customer',
          value: q,
        })
      }
    },
    load() {
      setTimeout(() => {
        this.$refs.input?.focus()
      }, 300);

      if (this.selected.prekey == 'search.text') {
        this.items = []
        this.placeholder = '검색어를 입력해주세요.'
        this.selected_index = 0
      } else if (this.selected.prekey == 'search.customer') {
        this.items = []
        this.placeholder = '고객정보를 입력해주세요.'
        this.selected_index = 0
      } else if (this.selected.prekey == 'search.field') {
        this.items = []
        this.placeholder = `(${this.selected.col.label}) 검색어를 입력해주세요.`
        this.selected_index = 0
        this.q = ''
        setTimeout(() => {
          this.do_filter()
        }, 100);
      } else if (this.selected.prekey == 'search.project') {
        this.items = []
        // this.placeholder = `(${this.selected.document_name}) 검색조건을 선택해주세요.`
        this.placeholder = `검색어 입력 또는 검색조건을 선택해주세요.`
        this.selected_index = 0
        this.q = ''
        setTimeout(() => {
          this.do_filter()
        }, 100);
      } else {
        this.items = [
          {
            key: 'search.text',
            prekey: 'search.text',
            label: '내용 검색',
          },
          {
            key: 'search.customer',
            prekey: 'search.customer',
            label: '고객정보 검색',
            description: '고객 아이디, 이름, 전화번호, 이메일주소',
          },
          // {
          //   key: 'search.project',
          //   prekey: 'search.project',
          //   label: '프로젝트 검색',
          // },
        ]
        for (const doc of this.documents) {
          this.items.push({
            key: 'search.project',
            prekey: 'search.project',
            label: `${doc.name} ${doc.id}`,
            document_id: doc.id,
            document_name: doc.name,
          })
        }
        // for (const doc of this.documents) {
        //   for (const col of doc.config.cols) {
        //     if (col.format[0] == '@') continue
        //     this.items.push({
        //       // key: 'search.field',
        //       prekey: 'search.field',
        //       label: `${col.label} 검색`,
        //       document_name: doc.name,
        //       col: {
        //         document_id: doc.id,
        //         key: col.key,
        //         label: col.label,
        //         format: col.format,
        //       },
        //     })
        //   }
        // }
      }
      this.filtered_items = this.items
    }
  },
};
</script>
