<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
.container-pane
  position: fixed
  // bottom: 0px
  // right: 0px
  // width: calc(100vw - 370px - 0px)
  // height: 50vh
  // overflow: scroll
  // border-color: #555 !important

  top: 0px
  right: 0px
  z-index: 1
  height: 100vh
  width: calc(50vw)
  min-width: 300px


  .th
    vertical-align: middle
.item-history
  // border-left: solid 2px #f8f9fa
  // font-size: 14px
  font-weight: 500
  // &.active
  //   border-left: solid 2px #007bff
  // &:hover
  //   border-left: solid 2px #007bff
  //   background-color: #fafafa
  //   cursor: pointer
  &:hover
    // border-left: solid 2px #007bff
    & > div
      background-color: #fafafa !important
    // background-color: #f0f0f0
    cursor: pointer
    .link-open
      opacity: 0.3
  &.active
    // border-left: solid 2px #007bff
    // background-color: #fafafa
    background-color: #fff
  .link-open
    opacity: 0

.text-fail
  // font-weight: bold
  color: mix(tomato, #555, 80%)
.detail-body
  cursor: initial
.message-type-SMS
  color: #f0f0f0 !important
  background-color: #3498db !important
  padding: 2px 3px
.message-type-EMAIL
  color: #f0f0f0 !important
  background-color: #1abc9c !important
  padding: 2px 3px
.btn-tag-close
  &:hover
    background-color: rgba(0,0,0,.0)
    svg
      background-color: rgba(0,0,0,.05)
      border-radius: 50%
.description
  width: 520px
  .description-scroll
    height: 600px
    overflow: scroll
.detail
  width: 350px
  height: 650px
  // width: 130px
  // border-right: solid 1px #ddd
  .label-overlay
    position: relative
    top: 36px
    left: 0px
    height: 36px
    margin-top: -36px
    margin-bottom: 0
    font-weight: 600
    color: #999
    // background-color: rgba(255,255,255,0.9)
  .form-group
    margin-top: -1px
    input
      padding-top: 10px
      font-weight: 600
      color: #555
  .detail-scroll
    overflow: scroll
    margin-bottom: 60px
  .detail-scroll-none
    overflow: hidden !important
.history
  width: 350px
  height: 650px
  .history-scroll
    height: 600px
    overflow: scroll
  .history-input
    // height: 100px
.diff-body
  min-height: 30px
  font-size: 12px
  // white-space: pre-line
  white-space: pre-wrap
  word-break: break-word
  text-align: right
  font-weight: 600
  .diff-line
    &.added
      // background-color: #B5EFDB
      background-color: mix(#fff, #B5EFDB, 50%)
      color: mix(#000, #B5EFDB, 70%)
    &.removed
      // background-color: #FF8983
      text-decoration: line-through
      opacity: 0.5
.item-history
  .hover-visible
    opacity: 0
  &:hover
    .hover-visible
      opacity: 1
.col-200
  max-width: 200px
  // width: inherit
  display: block
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
.col-100
  max-width: 100px
  // width: inherit
  display: inline-flex
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
.before
  // color: #999
  color: mix(#000, #B5EFDB, 70%)
  word-break: break-word
  white-space: break-spaces
.after
  background-color: mix(#fff, #B5EFDB, 50%)
  color: mix(#000, #B5EFDB, 70%)
  word-break: break-word
  white-space: break-spaces
.hover-visible-outer
  .hover-visible
    visibility: hidden
  &:hover
    .hover-visible
      visibility: visible
.input-edit
  // margin: -5px 0px -5px 0px
  // padding: 0px
  // margin-top: -8px
  // padding: 2px 3px
  // height: 30px
  background-color: #f8f9fa
  font-weight: 600
  border: solid 2px #fff
  // color: #007bff
  &:hover
    background-color: #f0f0f0
  &:focus
    background-color: #f8f9fa
    // color: #007bff
    &:hover
      // background-color: #f0f0f0
      // background-color: #fff
    // color: #007bff
    border: solid 2px #d4d4d4
.history-note
  background-color: mix(#f1c40f, #fff, 30%) !important
  border-bottom: solid 1px mix(#f1c40f, #fff, 40%) !important
  pre
    font-size: 12px
    color: #000
    // font-weight: 600
.input-tag
  color: #495057
  font-weight: 600
.tag-added
  background-color: mix(#fff, #B5EFDB, 50%)
  color: mix(#000, #B5EFDB, 50%)
.tag-removed
  background-color: mix(#fff, #e74c3c, 80%)
  color: mix(#000, #e74c3c, 2%)
  // opacity: 0.5
  // border: solid 3px limegreen
  // &:before
  //   content: "-"
  //   position: relative
  //   top: -20px
  //   color: #fff
  //   background
.select-single-hover
  background-color: #f8f9fa
  min-height: 35px
  padding-left: 3px
  padding-top: 5px
  line-height: 1rem
  cursor: pointer
  &:hover
    background-color: #f0f0f0
  .tag-item
    cursor: pointer
  border: solid 2px #fff
  &[aria-describedby]
    border: solid 2px #d4d4d4
.select-multiple-hover
  background-color: #f8f9fa
  min-height: 35px
  padding-left: 3px
  padding-top: 5px
  line-height: 1rem
  cursor: pointer
  &:hover
    background-color: #f0f0f0
  .tag-item
    cursor: pointer
  border: solid 2px #fff
  &[aria-describedby]
    border: solid 2px #d4d4d4
.word-break-keep-all
  word-break: keep-all
.col-list
  margin: 0
  // line-height: 14px
  // line-height: 16px
  line-height: 22px

  &:hover
    // box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.15)
    dt
      // transition: color linear 50ms
      color: #007bff
    dd
      cursor: pointer
      span.text
        // transition: color linear 50ms
        color: #007bff
      .diff-body
        color: #222 !important
        cursor: default
        // background-color: #f0f0f0 !important
    // border-bottom: solid 1px #f0f0f0
    // background-color: #f0f0f0
  dt
    // transition: color linear 50ms
    font-weight: normal
    font-size: 13px
    min-width: 140px
    color: #777
    // letter-spacing: -1px
  dd
    span
      // transition: color linear 50ms
    width: 100%
    min-height: 14px
    font-weight: normal
    font-size: 14px
    color: #222
.col-list-editing
  margin: 0
  // line-height: 14px
  // line-height: 16px
  line-height: 22px

  // &:hover
  //   // box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.15)
  //   dt
  //     // transition: color linear 50ms
  //     color: #007bff
  //   dd
  //     cursor: pointer
  //     span.text
  //       // transition: color linear 50ms
  //       color: #007bff
  //     .diff-body
  //       color: #222 !important
  //       cursor: default
  //       // background-color: #f0f0f0 !important
  //   // border-bottom: solid 1px #f0f0f0
  //   // background-color: #f0f0f0
  dt
    // transition: color linear 50ms
    font-weight: normal
    font-size: 13px
    min-width: 140px
    color: #777
    // letter-spacing: -1px
  dd
    span
      // transition: color linear 50ms
    width: 100%
    min-height: 14px
    font-weight: normal
    font-size: 14px
    color: #222
.input-hover-expand
  // width: 200px
  &:focus
    position: absolute
    // top: 1px
    width: 525px
    z-index: 1
    height: 14rem
    // border: solid 1px rgba(0,0,0,.05) !important
    // box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15), inset 0px 0px 0px 1px rgba(0,0,0,.1) !important
    box-shadow: 0 1rem 3rem rgba(0,0,0,.18), inset 0px 0px 0px 1px rgba(0,0,0,.1) !important
.input-single-hover-expand
  max-height: 2rem
  // width: 200px
  &:focus
    // max-height: initial
    max-height: 14rem
    position: absolute
    // top: 1px
    width: 525px
    z-index: 1
    // height: 4rem
    // border: solid 1px rgba(0,0,0,.05) !important
    // box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15), inset 0px 0px 0px 1px rgba(0,0,0,.1) !important
    box-shadow: 0 1rem 3rem rgba(0,0,0,.18), inset 0px 0px 0px 1px rgba(0,0,0,.1) !important

.card-project-record
  font-size: 14px
  &:hover
    background-color: rgba(darken(#f8f9fa, 50%), .03)
    cursor: pointer
.pipeline-status
  border-top: solid 2px #d4d4d4
  color: #999
  &.pipeline-status-on
    border-top: solid 2px #007bff !important
    color: #007bff
.col-textarea
  line-height: 1.5
.relation-tab-content
  width: 1110px
.btn-tab
  // border-radius: 0.25rem 0.25rem 0 0
  border-radius: 0 0 0 0
  margin-bottom: -2px
  margin-right: -1px
.tab-selected
  margin-bottom: -1px
  position: relative
  z-index: 1
  color: #007bff
</style>
<template lang="pug">
div(@click='blur_popover')
  //- div(@click='blur_popover' @paste.stop='onPaste')
  //- .bg-light.d-flex
  //-   .flex-fill.w-50.border-right
  //-     button.btn.btn-light 공유 링크
  //-   .flex-fill.w-50
  .d-flex.bg-light(:class='[depth ? "" : "header-draggable" ]' v-if='!depth' style='height: 50px')
    div.pl-3(style='font-size: 16px; font-weight: 600; line-height: 50px') {{document.name}}
    //- .p-2(v-show='saving' style='position: absolute; bottom: 0px; color: #555; font-weight: 600')
    .p-2.ml-3.text-secondary(v-show='saving' style='font-weight: 600; line-height: 35px; font-size: 12px')
      b-spinner.mr-2(label='저장중...' small variant='secondary')
      | 저장중 ...
    div.ml-auto.pt-2
      button.btn.btn-default.opacity-50(v-show='relation && relation.id' type='button' @click='remove_relation()' v-b-tooltip.click.blur="`연결 삭제함`" title="클릭하여 연결끊기" )
        | 연결 삭제
      button.btn.btn-default.opacity-50(type='button' @click='copy_link' v-b-tooltip.click.blur="`복사됨`" title="클릭하여 복사" )
        b-icon-link45deg.mr-1
        | 링크복사


  .bg-light.w-100(style='overflow: hidden' v-if='!depth && tabs.length > 0')
    div.d-inline-block.py-2.px-3.btn-tab.btn.btn-light.border.ml-3(:class='{"bg-white shadow tab-selected":(selected_tab == "")}' @click='select_tab()') 조회
    template(v-for='tab in tabs')
      div.d-inline-block.py-2.px-3.btn-tab.btn.btn-light.border(:class='{"bg-white shadow tab-selected":(selected_tab == tab.id)}' @click.keydown.meta.stop='open_relation_as_new_tab(tab.col, tab.value)' @click.keydown.ctrl.stop='open_relation_as_new_tab(tab.col, tab.value)' @click.exact='select_tab(tab, false)' )
        span(v-if='$store.state.documents_by_id && $store.state.documents_by_id[tab.col.document_id]') {{ $store.state.documents_by_id[tab.col.document_id].name }}
          small.text-muted.ml-2 {{ tab.value.value }}
    //- template(v-for='relation in relations' v-if='count_relations_by_document_id[relation.document_id] == 1')
    //- template(v-for='relation in relations' v-if='count_relations_by_document_id[relation.target_document_id] == 1')
    //-   div.d-inline-block.py-2.px-3(:class='{"bg-white shadow":(selected_tab == relation.id)}' @click.keydown.meta.stop='open_tab_new(relation)' @click.keydown.ctrl.stop='open_tab_new(relation)' @click.exact='select_tab(relation, false)' ) {{ $store.state.documents_by_id[relation.target_document_id].name }}
    //- //- template(v-for='relation in relations' v-if='count_relations_by_document_id[relation.target_document_id] > 1 && relation.is_first')
    //- template(v-for='relation in relations' v-if='count_relations_by_document_id[relation.target_document_id] > 1 && relation.is_first')
    //-   //- pre {{relation}}
    //-   div.d-inline-block.py-2.px-3(:class='{"bg-white shadow":(selected_tab == relation.id || selected_relation.target_document_id == relation.target_document_id)}' @click='select_tab(relation, false)') {{ $store.state.documents_by_id[relation.target_document_id].name }}
    //-     span.opacity-50.ml-1 ({{count_relations_by_document_id[relation.target_document_id]}})

    //- div.d-inline-block.py-2.px-3.opacity-50(@click='add_relation()')
    //-   b-icon-plus
    //-   | 추가
  .d-flex
    div(v-show='selected_tab != ""')
      //- div(v-if='selected_relation.show_list && !selected_relation.document_record_id')
      //-   .p-2(style='min-height: 650px')
      //-     .p-1.shadow-sm.border.rounded.mb-1.card-project-record(v-for='r of relations' v-if='r.target_document_id == selected_relation.target_document_id'
      //-       @click.keydown.meta.stop='open_tab_new(r)' @click.keydown.ctrl.stop='open_tab_new(r)'
      //-       @click.exact='select_tab_item(r)'
      //-     )
      //-       span.d-block(style='word-break: break-word;') {{r.customer_text}}
      //-       span.d-block.opacity-50(v-if='r.field_text && r.field_text.length' style='word-break: break-word;') {{r.field_text}}
      //-       span.d-block.col-150.opacity-50(v-else) 비어있음
      //- div(v-else)
      div.relation-tab-content(v-for='tab in tabs' v-show='tab.id == selected_tab')
        //- pre {{property}}
        //- pre {{selected_relation}}
        document-record(
          :property='property'
          :document='$store.state.documents_by_id[tab.col.document_id]'
          :document_record_id='tab.value.id'
          :lookup_candidates_by_value='tab.lookup_candidates_by_value'
          :depth='1+(depth || 0)'
          :did_open_relation_as_tab='did_open_relation_as_tab || open_relation_as_tab'
        )
        //- @loaded='record_did_loaded'
        //- @updated='record_did_updated'
        //- @closing='$modal.hide("record")'
  div(v-show='selected_tab == ""')
    .d-flex
      .description.flex-fill.p-4
        .description-scroll(v-if='row && row.json')
          //- button.btn.btn-default.btn-sm.px-2.py-1.shadow-sm.border.bg-white.float-right(type='button' @click='toggle_edit()' style='margin-bottom: -50px') 저장
          input.form-control.shadow-none.p-0.title.mb-4(v-model='row.name_text' style='font-size: 2rem; height: 2rem;' placeholder='제목' @blur='save_page_name()' autofocus @keypress.enter.prevent='focus_desc()')
          //- textarea(v-model='row.desc_text')
          tiptap(v-model='row.desc_text' placeholder :focus_ts='state_focus_desc')
          //- div(v-if='is_editing')
          //- div(v-else) {{row.desc_text}}
          button.btn.btn-default.btn-sm.px-4.py-2.shadow-sm.border.bg-white(type='button' @click='save_page_desc()') 저장
      .detail.flex-fill.border-left
        //- .bg-light.text-right.border-right
        //- form.form.d-flex.flex-column.h-100(@submit.prevent.stop='' v-if='row && row.json')
        div.d-flex.flex-column.h-100(v-if='row && row.json')
          .detail-scroll(ref='detail-scroll' :class='{"detail-scroll-none": is_expand}')
            div.p-4.shadow.m-2.alert.alert-warning.border(style='color: #888;' v-show='row.deleted_at')
              h5.w-100.mb-0.text-dark 삭제된 내역입니다.
                button.btn.btn-default.border.btn-sm.bg-white.mr-3.float-right(type='button' @click='remove_undo') 복구

            template(v-if='!is_editing')
              .p-3()
                dl.d-flex.col-list.hover-visible-outer(v-for='(col, $index) in document.config.cols' :class='{"flex-wrap col-textarea":(col.format=="textarea")}')
                  //- @mouseenter='set_last_hover_col(col)'
                  dt.flex-fill.flex-wrap.d-flex
                    span.text(style='word-break: break-word; white-space: break-spaces') {{col.label}}
                    a.hover-visible.ml-auto.text-muted.mr-2.opacity-50(style='text-decoration: none; cursor: pointer' @click.prevent='copy_value(col)' tabindex='0' v-b-tooltip.click.blur="`복사됨`" title="클릭하여 복사") 복사
                  dd.flex-fill.flex-wrap
                    //- button.btn.btn-default.btn-sm.hover-visible.float-right(type='button' @click='toggle_edit(col)' title="수정 열기" style='margin: -9px; margin-top: -7px;') 수정
                    template(v-if='col.format == "textarea" ')
                      .diff-body.bg-light.p-2.text-left.w-100.d-block(@click='toggle_edit(col)' title="수정 열기" style='cursor: pointer') {{row.json[col.key]}}
                        .text-right
                          button.btn.btn-default.btn-sm.hover-visible(type='button' @click.stop='copy_value(col)' tabindex='0' v-b-tooltip.click.blur="`복사됨`" title="클릭하여 복사" style='margin: -9px') 복사
                          //- button.btn.btn-default.btn-sm.hover-visible(type='button' @click='toggle_edit(col)' title="수정 열기" style='margin: -9px; margin-left: 10px;') 수정
                    template(v-else)

                      div.hover-visible-outer(@click='toggle_edit(col)' title="수정 열기")
                        template(v-if='col.format == "select"')
                          cell-tag(
                            v-if='document.config.cols_options && document.config.cols_options[col.key]'
                            v-model='row.json[col.key]' :col='col' :cols_options='document.config.cols_options[col.key]')
                        template(v-else-if='col.format == "date"')
                          cell-date(v-model='row.json[col.key]' :col='col')
                        template(v-else-if='col.format == "number"')
                          cell-number(v-model='row.json[col.key]' :col='col')
                        template(v-else-if='col.format == "check" ')
                          b-form-checkbox.rounded-0(style='margin-right: 0px; margin-bottom: 0px' v-model='row.json[col.key]' value='Y' unchecked-value='N' disabled @click='toggle_edit(col)' ) {{col.description}}
                          //- span(v-else) {{row.json[col.key]}}

                          //- template(v-else-if='col.format == "number" ')
                          //-   span.text() {{row.json[col.key] | number}}
                          //- template(v-else-if='col.format == "select" ')
                          //-   .mb-1(style='min-height: 16px')
                          //-     span.tag-item.rounded.mr-1.mb-1.d-inline-block(
                          //-       v-if='row.json[col.key] && isArray(row.json[col.key]) && document.config.cols_options[col.key] && document.config.cols_options[col.key][tag_id]' v-for='tag_id in row.json[col.key]' :key='tag_id' :style='{backgroundColor: (document.config.cols_options[col.key][tag_id].color || "#e4e4e4")}'
                          //-     ) {{document.config.cols_options[col.key][tag_id].name}}
                          //- template(v-else-if='col.format == "date" ')
                          //-   template(v-if='row.json[col.key] && row.json[col.key].date')
                          //-     .d-flex.flex-wrap
                          //-       span
                          //-         span.text {{row.json[col.key].date | date}}
                          //-         span.text.ml-1(v-show='row.json[col.key].use_time == "Y"') {{row.json[col.key].time | time}}
                          //-       span.mx-1.opacity-50(v-show='row.json[col.key].use_end_date == "Y"') →
                          //-       span
                          //-         span.text {{row.json[col.key].end_date | date}}
                          //-         span.text.ml-1(v-show='row.json[col.key].use_time == "Y"') {{row.json[col.key].end_time | time}}
                        template(v-else-if='col.format == "relation" ')
                          template(v-if='lookup_candidates_by_value' v-for='value in lookup_candidates_by_value[row.json[col.key]]')
                            a.btn.btn-default.btn-sm.shadow-sm.border.px-2(style='margin-top: -3px' @click.stop='open_relation_as_tab(col, value)')
                              | {{row.json[col.key]}}
                              small.ml-2.opacity-50: b-icon-box-arrow-right
                        template(v-else-if='col.format == "link" ')
                          a(v-if='row.json[col.key]' :href='row.json[col.key]' title='새 탭으로 열기' target='_blank' @click.stop='' style='margin-top: -3px; white-space: break-spaces; word-break: break-all;') {{row.json[col.key]}}
                          //- a.btn.btn-default.btn-sm.shadow-sm.border.px-2(v-if='row.json[col.key]' :href='row.json[col.key]' v-b-tooltip.hover="row.json[col.key]" title='열기' target='_blank' @click.stop='' style='margin-top: -3px')
                          //-   | 새 탭으로 열기
                          //-   small.ml-2.opacity-50: b-icon-box-arrow-up-right
                          //- //- span.opacity-50(v-else) -
                          //- button.btn.btn-default.btn-sm.shadow-sm.border.px-2.opacity-50(v-else disabled type='button' title='비어있음' target='_blank' @click.stop='' style='margin-top: -3px')
                          //-   | 비어있음
                          //-   //- small.ml-2.opacity-50: b-icon-box-arrow-up-right
                        template(v-else)
                          span.text(style='word-break: break-word; white-space: break-spaces') {{row.json[col.key] || '-'}}
                .mb-2.border-top.opacity-50(style='margin: 0 -1rem')
                dl.d-flex.col-list
                  dt 생성일
                  dd
                    span.text {{row.created_at}}
                dl.d-flex.col-list
                  dt 수정일
                  dd
                    span.text {{row.updated_at || '-'}}
                //- dl.d-flex.col-list
                  dt 파이프라인
                  dd
                    select.form-control(style='width: 100px; height: 1.4rem; padding: 0.2rem; font-size: 12px' v-model='current_pipeline_id' placeholder='파이프라인 추가')
                      option(v-for='p in $store.state.pipelines' :value='p.id' v-if='!pipeline_added[p.id]') {{p.name}}
                    select.form-control(style='width: 100px; height: 1.4rem; padding: 0.2rem; font-size: 12px' v-model='current_pipeline_status' v-if='current_pipeline_id')
                      template(v-for='p in $store.state.pipelines' v-if='p.id == current_pipeline_id')
                        option(v-for='s in p.config.status' :value='s.id') {{s.name}}
                    button.btn.btn-default.btn-sm.shadow-sm.border(style='width: 100px; height: 1.4rem; padding: 0.1rem; font-size: 12px' type='button' v-if='current_pipeline_status' @click='add_pipeline_status()') 추가

                //- 파이프라인
                //- .border.shadow-sm
                //- pre {{pipelines}}


                div.clearfix(style='padding-top: 100px')
                div.bg-light(style='position: absolute; bottom: 0px; left: 485px; width: 322px; height: 61px; border-top: solid 1px #d4d4d4')
                  button.btn.btn-light.text-primary.w-100(type='button' @click='toggle_edit()' style='line-height: 48px; border-radius: 0px 0px') 내용 수정

            template(v-else)
              form.h-100.p-3(@submit.prevent.stop='')
                div.d-flex.col-list-editing.flex-wrap(v-for='(col, $index) in document.config.cols' style='line-height: 30px')
                  dt.flex-fill
                    span.text(style='word-break: break-word; white-space: break-spaces'
                      :id='`opt_field_${col.key}`' :tabindex='`01_${$index}`' @click.stop='$root.$emit("bv::hide::popover"); $root.$emit("bv::show::popover", `opt_field_${col.key}`)'
                    ) {{col.label}}
                    b-popover(:ref='`opt_field_${col.key}`' :target='`opt_field_${col.key}`' triggers='click' no-fade placement='bottom' custom-class='popover-dropdown')
                      div
                        span.dropdown-label.px-2 항목이름
                        .p-2
                          input.form-control.form-control-sm(type='text' v-model.lazy='col.label' @change='save_document_col')
                  dd.flex-fill.hover-visible-outer
                    template(v-if='col.format == "text" ')
                      //- input.form-control.input-edit.border-0.input-single-hover-expand(:ref='`input_field_${col.key}`' :tabindex='`00_${$index}`' type='text' v-model='row.json[col.key]' @focus='is_expand = true' @blur='save(); is_expand = false' @keypress.enter.exact='save')
                      input.form-control.input-edit.shadow-none(:ref='`input_field_${col.key}`' :tabindex='`00_${$index}`' type='text' v-model='row.json[col.key]' @blur='save()' @keypress.enter.stop.prevent='save')
                    template(v-else-if='col.format == "link" ')
                      div(style='height: 30px;')
                        input.form-control.input-edit.input-single-hover-expand(:ref='`input_field_${col.key}`' type='text' v-model='row.json[col.key]' rows=1 @focus='focus_expand(col.key)' @blur='save(); blur_expand(col.key);' :style='{marginTop: expand_top}')
                      //- input.form-control.input-edit.border-0.input-single-hover-expand(:ref='`input_field_${col.key}`' :tabindex='`00_${$index}`' type='text' v-model='row.json[col.key]' @focus='is_expand = true' @blur='save(); is_expand = false' @keypress.enter.exact='save')
                      //- div(style='height: 33px')
                        //- textarea.form-control.input-edit.input-single-hover-expand(:ref='`input_field_${col.key}`' type='text' v-model='row.json[col.key]' rows=1 @focus='focus_expand(col.key)' @blur='save(); blur_expand(col.key);' :style='{marginTop: expand_top}')
                        textarea-autosize.form-control.input-edit.shadow-none.input-single-hover-expand(:ref='`input_field_${col.key}`' type='text' v-model='row.json[col.key]' rows=1 @focus='focus_expand(col.key)' @blur.native='save(); blur_expand(col.key);' :style='{marginTop: expand_top}')
                    template(v-else-if='col.format == "check" ')
                      b-form-checkbox.rounded-0.ml-3(:ref='`input_field_${col.key}`' :tabindex='`00_${$index}`' v-model='row.json[col.key]' value='Y' unchecked-value='N' style='padding: 0px 10px' @input='save') {{col.description}}
                      //- div.float-right
                      //- .clearfix
                    template(v-else-if='col.format == "id" ')
                      input.form-control.input-edit.shadow-none(:ref='`input_field_${col.key}`' :tabindex='`00_${$index}`' type='text' @blur='save' v-model='row.json[col.key]' @keypress.enter.stop.prevent='save')
                    template(v-else-if='col.format == "relation" ')
                      input.form-control.input-edit.shadow-none(:ref='`input_field_${col.key}`' :tabindex='`00_${$index}`' type='text' @blur='save' v-model='row.json[col.key]' @keypress.enter.stop.prevent='save')
                    template(v-else-if='col.format == "@고객아이디" ')
                      input.form-control.input-edit.shadow-none(:ref='`input_field_${col.key}`' :tabindex='`00_${$index}`' type='text' @blur='save' v-model='row.json[col.key]' @keypress.enter.stop.prevent='save')
                    template(v-else-if='col.format == "@고객연락처" ')
                      input.form-control.input-edit.shadow-none(:ref='`input_field_${col.key}`' :tabindex='`00_${$index}`' type='text' @blur='save' v-model='row.json[col.key]' @keypress.enter.stop.prevent='save')
                    template(v-else-if='col.format == "@고객이름" ')
                      input.form-control.input-edit.shadow-none(:ref='`input_field_${col.key}`' :tabindex='`00_${$index}`' type='text' @blur='save' v-model='row.json[col.key]' @keypress.enter.stop.prevent='save')
                    template(v-else-if='col.format == "@고객이메일" ')
                      input.form-control.input-edit.shadow-none(:ref='`input_field_${col.key}`' :tabindex='`00_${$index}`' type='email' @blur='save' v-model='row.json[col.key]' @keypress.enter.stop.prevent='save')
                    template(v-else-if='col.format == "select" ')
                      //- pre {{document.config.cols_options}}
                      //- pre {{row.json[col.key]}}

                      template(v-if='col.max')
                        div.select-single-hover.rounded(:ref='`input_field_${col.key}`' :id='`opt_select_${col.key}`')
                          //- transition-group(type='transition' name='tag')
                          cell-tag(
                            v-if='document.config.cols_options && document.config.cols_options[col.key]'
                            v-model='row.json[col.key]' :col='col' :removed='remove_tag' :cols_options='document.config.cols_options[col.key]')
                            //- a(href='#' @click.prevent.stop='remove_tag(col, tag_id)' style='border-left: 0 !important'): b-icon-x(style='color: #fff')
                          //- span.tag-item.rounded.mr-1.mb-1.d-inline-block(v-if='row.json[col.key] && isArray(row.json[col.key]) && document.config.cols_options[col.key] && document.config.cols_options[col.key][tag_id]' v-for='tag_id in row.json[col.key]' :key='tag_id' :style='{backgroundColor: (document.config.cols_options[col.key][tag_id].color || "#e4e4e4")}'
                          //- )
                          //-   span.mr-1 {{document.config.cols_options[col.key][tag_id].name}}
                            a(href='#' @click.prevent.stop='remove_tag(col, tag_id)' style='border-left: 0 !important'): b-icon-x(style='color: #fff')
                      template(v-else)
                        div.select-multiple-hover.rounded(:ref='`input_field_${col.key}`' :id='`opt_select_${col.key}`')
                          cell-tag(
                            v-if='document.config.cols_options && document.config.cols_options[col.key]'
                            v-model='row.json[col.key]' :col='col' :removed='remove_tag' :cols_options='document.config.cols_options[col.key]')
                          //- transition-group(type='transition' name='tag')
                            //- span.tag-item.rounded.mr-1.mb-1.d-inline-block(v-if='row.json[col.key] && isArray(row.json[col.key]) && document.config.cols_options[col.key] && document.config.cols_options[col.key][tag_id]' v-for='tag_id in row.json[col.key]' :key='tag_id' :style='{backgroundColor: (document.config.cols_options[col.key][tag_id].color || "#e4e4e4")}')
                              span.mr-1 {{document.config.cols_options[col.key][tag_id].name}}
                              a(href='#' @click.prevent.stop='remove_tag(col, tag_id)' style='border-left: 0 !important'): b-icon-x(style='color: #fff')
                              //- a(href='#' @click.prevent.stop='remove_tag(col, tag_id)' style='border-left: 0 !important'): b-icon-x(style='color: #555')

                        //- .text-right.mt-2(style='margin-bottom: -0.5rem')
                          button.btn.btn-default.mr-1.mb-1(type='button' :id='`opt_select_${col.key}`')
                            small.mr-1: b-icon-plus
                            | 추가
                            //- button.btn.btn-primary.px-2.py-1.shadow-sm.mr-1.mb-1.float-right(type='button' @click='search_tag()')

                      b-popover(:ref='`opt_select_${col.key}`' :target='`opt_select_${col.key}`' triggers='click' no-fade placement='right' boundary='viewport' custom-class='popover-dropdown' @hidden='tag_select_hidden')
                        form(@submit.prevent.stop='')
                          search-tag2(:col='col' :document='document' :value='row.json[col.key]' @updated='tag_did_updated' @updated_col_option='tag_option_did_updated')
                          //- :defaultSelected=''
                    template(v-else-if='col.format == "number" ')
                      vue-numeric.form-control.border-0.input-edit.text-right(:ref='`input_field_${col.key}`' separator=',' v-model='row.json[col.key]' @change='save')
                    template(v-else-if='col.format == "textarea" ')
                      div(style='height: 113px')
                        textarea.form-control.input-edit.input-hover-expand(:ref='`input_field_${col.key}`' type='text' v-model='row.json[col.key]' rows=5 @focus='focus_expand(col.key)' @blur='save(); blur_expand(col.key);' :style='{marginTop: expand_top}')
                    template(v-else-if='col.format == "date" ')
                      div.select-single-hover.rounded.d-flex.flex-wrap.input-edit(:ref='`input_field_${col.key}`' :id='`opt_date_${col.key}`' style='padding: 0.5rem 0.75rem')
                        cell-date(v-model='row.json[col.key]' :col='col' :removed='remove_date')
                        //- template(v-if='row.json[col.key] && row.json[col.key].date')
                        //-   span
                        //-     span {{row.json[col.key].date | date}}
                        //-     span.ml-1(v-show='row.json[col.key].use_time == "Y"') {{row.json[col.key].time | time}}
                        //-   span.mx-1.opacity-50(v-show='row.json[col.key].use_end_date == "Y"') →
                        //-   span
                        //-     span(v-show='row.json[col.key].date != row.json[col.key].end_date') {{row.json[col.key].end_date | date}}
                        //-     span.ml-1(v-show='row.json[col.key].use_time == "Y"') {{row.json[col.key].end_time | time}}
                        //-   button.btn.btn-default.p-0.ml-auto(href='#' @click.prevent.stop='row.json[col.key] = {}' style='border-left: 0 !important'): b-icon-x
                        //- template(v-else) 비어있음
                      b-popover(:ref='`opt_date_${col.key}`' :target='`opt_date_${col.key}`' triggers='click' no-fade placement='right' boundary='viewport' custom-class='popover-dropdown popover-dropdown-search-date' @hidden='date_select_hidden')
                        form(@submit.prevent.stop='')
                          search-date(mode='datetimerange' :col='col' :document='document' :value='row.json[col.key]' @updated='date_did_updated')
                    template(v-else)
                      span(style='word-break: break-word; white-space: break-spaces') {{row.json[col.key]}}

                div.d-flex.col-list-editing.flex-wrap
                  dt.flex-fill
                    //- span 추가작업
                  dd.flex-fill.hover-visible-outer
                    button.btn.btn-default.text-secondary(type='button' @click='remove' v-show='is_editing') 삭제
                      //- span.text-muted.opacity-50 ID:{{row.id}}
                //- .d-flex.px-3.pb-3
                  button.btn.btn-default.text-primary.w-100.mx-2(type='button' @click='remove' v-show='is_editing')
                    //- b-icon-trash.opacity-50.mr-1
                    span.text-muted.float-left.opacity-50 ID:{{row.id}}
                    span.float-right 휴지통으로 이동
                //- .d-flex.px-3.pb-3
                div(style='padding-top: 61px')
                div.bg-light(style='position: absolute; bottom: 0px; left: 485px; width: 322px; height: 61px; border-top: solid 1px #d4d4d4')
                  button.btn.btn-primary.w-100(type='submit' @click='click_save' v-show='is_editing' style='line-height: 48px; border-radius: 0px 0px')
                    //- b-icon-check.opacity-50.mr-1
                    span 수정 완료


          //- button.btn.btn-block.btn-light.rounded-0.mt-auto(type='button' style='line-height: 1.7rem' @click='$emit("closing")') 닫기
          //- .d-flex.flex-column
            div
        //- span(v-show='saving' style='position: absolute; bottom: 0px; color: #555')
      //- .history.w-50.flex-fill.bg-light.d-flex.flex-column
      .history.flex-fill.d-flex.flex-column.border-left
        template
          div.border-top.border-bottom.bg-light(v-if='pipelines.length')
            div(v-for='p in pipelines' v-if='$store.state.pipelines_by_id && $store.state.pipelines_by_id[p.pipeline_id] && $store.state.pipelines_by_id[p.pipeline_id].config')
              div.hover-visible-outer.border(style='position: relative;')
                //- (style='border-left: solid 2px #ccc')
                .pt-1
                  router-link.btn.btn-default.btn-sm.text(style='margin-top: -0.5rem' :to='{name: "pipeline.view", params: {pipeline_id: p.pipeline_id, pipeline_name: $options.filters.encodeReadableText($store.state.pipelines_by_id[p.pipeline_id].name)} }') {{$store.state.pipelines_by_id[p.pipeline_id].name}}
                  a.float-right.btn.btn-default.btn-sm.text-danger.shadow-sm.mr-1.hover-visible.py-0(style='margin-top: -3px' href='#' @click.prevent='delete_pipeline_status(p)') 삭제
                  //- router-link.float-right.btn.btn-default.btn-sm.shadow-sm.border.hover-visible(style='margin-top: -0.5rem' :to='{name: "pipeline.view", params: {pipeline_id: p.pipeline_id, pipeline_name: $options.filters.encodeReadableText($store.state.pipelines_by_id[p.pipeline_id].name)} }') 열기
                  //- , document_record_id: p.record_id
                //- .btn-group.w-100.pt-1
                .d-flex.flex-wrap.btn-group.w-100.pt-0.bg-white
                  button.btn.btn-sm.rounded-0.py-2.btn-default.pipeline-status(v-for='s in $store.state.pipelines_by_id[p.pipeline_id].config.status'
                    :class='[(s.id == p.state.id) ? "pipeline-status-on":"opacity-50"]'
                    type='button'
                    @click='update_pipeline_status(p, s)'
                  ) {{s.name}}
                  //- :class='{"pipeline-status-on": (s.id == p.state.id), "pipeline-status-off": (s.id != p.state.id)}'

        .history-scroll.flex-fill.logs(ref='logs')
          .mt-3
          div.item-history.border-0(v-for='log in logs' v-if='logs_loaded')
            //- .bg-white.m-2.shadow-sm.p-2.rounded-lg(v-if='log.field == "note"')
            div(v-if='log.field == "note"')
              .bg-white.m-2.border-bottom
                .history-note.p-2
                  //- small.float-right.opacity-50(v-b-tooltip.top :title='$options.filters.datetime(log.created_at)') {{ log.created_at | datetime_dow }}
                  //- strong {{log.username}}
                  .diff-body.text-left.py-1(style='color: #000;') {{ log.json.note }}
                  template(v-if='log.json.date && log.json.date.date')
                    span.bg-light.border.border-secondary.p-1.rounded.shadow-sm.text-dark
                      b-icon-calendar-date.text-danger.mr-1.opacity-50
                      strong {{log.json.date.date | localdate}}
                      span.ml-1(v-show='log.json.date.use_time == "Y"') {{log.json.date.time | time}}
                  button.btn.btn-light.btn-sm.px-2.py-1.shadow-sm.border.btn-block.hover-visible(v-show='log.user_id == session.id' @click='edit_note(log)') 수정
                .opacity-50
                  small {{log.username}}
                  small.ml-3 {{ log.created_at | datetime_dow }}
                  small.font-weight-bold.float-right(v-if='log.slack_json && $store.state.slack_config_channels_by_id && $store.state.slack_config_channels_by_id[log.slack_json.channel_id]')
                    a.text-dark(:href='`slack://channel?team=${log.slack_json.team}&id=${log.slack_json.channel_id}&message=${log.slack_json.ts}`') \#{{$store.state.slack_config_channels_by_id[log.slack_json.channel_id].name }}

            div(v-else-if='log.field == "page_name"')
              .bg-white.m-2.border-bottom
                .p-2.d-flex
                  span.mr-auto
                    strong 제목
                    |   변경
                  //- .d-flex.justify-content-end.flex-wrap(v-if='log.json.before.name && log.json.after.name')
                  //-   span.ml-1.mr-1.before(style='word-break: break-word; white-space: break-spaces') {{log.json.before.name}}
                  //-   span
                  //-     b-icon-arrow-right
                  //-   span.ml-1.mr-1.after {{log.json.after.name}}
                  //- .d-flex.justify-content-end.flex-wrap(v-else-if='log.json.before.name && !log.json.after.name')
                  //-   span.ml-1.mr-1.before(style='word-break: break-word; white-space: break-spaces') {{log.json.before.name}}
                  //-   span
                  //-     b-icon-arrow-right
                  //-   span.ml-1.mr-1.opacity-50 (없음)
                  .justify-content-end
                    span.ml-1.mr-1.after(style='word-break: break-word; white-space: break-spaces') {{log.json.name}}
                .opacity-50
                  small {{log.username}}
                  small.ml-3 {{ log.created_at | datetime_dow }}
            div(v-else-if='log.field == "page_desc"')
              .bg-white.m-2.border-bottom
                .p-2.d-flex
                  span.mr-auto
                    strong 내용
                    |   변경
                  .diff-body.bg-light.p-2.text-left.mt-1
                    span.diff-line(v-for='d in log.diff' :class='{added: d.added, removed: d.removed}') {{d.value}}
                .opacity-50
                  small {{log.username}}
                  small.ml-3 {{ log.created_at | datetime_dow }}
            div(v-else-if='log.field == "pipeline"')
              .bg-white.m-2.border-bottom
                .p-2
                  strong(style='word-break: break-word; white-space: break-spaces' v-if='$store.state.pipelines_by_id[log.json.pipeline_id]') {{$store.state.pipelines_by_id[log.json.pipeline_id].name}}
                  span(v-if='log.json.before.name && log.json.after.name')  파이프라인 변경
                  span(v-else-if='log.json.before.name && !log.json.after.name')  파이프라인 삭제
                  span(v-else)  파이프라인 추가
                  .d-flex.justify-content-end.flex-wrap(v-if='log.json.before.name && log.json.after.name')
                    span.ml-1.mr-1.before(style='word-break: break-word; white-space: break-spaces') {{log.json.before.name}}
                    span
                      b-icon-arrow-right
                    span.ml-1.mr-1.after {{log.json.after.name}}
                  .d-flex.justify-content-end.flex-wrap(v-else-if='log.json.before.name && !log.json.after.name')
                    span.ml-1.mr-1.before(style='word-break: break-word; white-space: break-spaces') {{log.json.before.name}}
                    span
                      b-icon-arrow-right
                    span.ml-1.mr-1.opacity-50 (없음)
                  .d-flex.justify-content-end.flex-wrap(v-else)
                    span.ml-1.mr-1.after(style='word-break: break-word; white-space: break-spaces') {{log.json.after.name}}
                .opacity-50
                  small {{log.username}}
                  small.ml-3 {{ log.created_at | datetime_dow }}
            .bg-white.m-2.p-2.border-bottom(v-else)
              .d-flex.flex-wrap
                .flex-fill.mr-auto
                  //- small.float-right.opacity-50(v-if='log.json.col.format == "textarea" ' v-b-tooltip.right :title='$options.filters.datetime(log.created_at)') {{ log.created_at | datetime_dow }}
                  //- strong {{log.username}}
                  //- span {{log.username_postfix}}
                  strong(style='word-break: break-word; white-space: break-spaces') {{log.json.col.label}}
                  span {{log.col_postfix}}
                  template(v-if='log.json.col.format == "select" ')
                    span(v-if='log.added.length && !log.removed.length')
                      span.word-break-keep-all.ml-1 추가
                    span(v-else-if='!log.added.length && log.removed.length')
                      span.word-break-keep-all.ml-1 삭제
                    span(v-else)
                      span.word-break-keep-all.ml-1 변경
                  template(v-else)
                    span(v-if='log.json.before && log.json.after')
                      span.word-break-keep-all.ml-1 변경
                    span(v-else-if='log.json.before && !log.json.after')
                      span.word-break-keep-all.ml-1 삭제
                    span(v-else)
                      span.word-break-keep-all.ml-1 추가
                .flex-fill.text-right(v-if='log.json.col.format == "select" ')
                  //- span {{log.is_changed}}
                  //- span {{log.added}}
                  //- span {{log.removed}}
                  span.tag-added.rounded-circle(style='padding: 0px 1px; margin-right: 2px;' v-show='log.added.length')
                    b-icon-plus
                  cell-tag.d-inline-block(v-model='log.added' :col='log.json.col' :cols_options='document.config.cols_options[log.json.col.key]')
                  //- span.tag-item.rounded.mr-1.mb-1.d-inline-block(
                  //-   v-if='document.config.cols_options && document.config.cols_options[log.json.col.key] && document.config.cols_options[log.json.col.key][tag_id]' v-for='tag_id in log.added' :key='tag_id' :style='{backgroundColor: (document.config.cols_options[log.json.col.key][tag_id].color || "#e4e4e4")}'
                  //- ) {{document.config.cols_options[log.json.col.key][tag_id].name}}
                  span.tag-removed.rounded-circle(style='padding: 0px 1px; margin-right: 2px;' v-show='log.removed.length')
                    b-icon-dash
                  cell-tag.d-inline-block(v-model='log.removed' :col='log.json.col' :cols_options='document.config.cols_options[log.json.col.key]')
                  //- pre {{log.removed}}
                  //- span.tag-item.rounded.mr-1.mb-1.d-inline-block.opacity-50(
                  //-   v-if='document.config.cols_options && document.config.cols_options[log.json.col.key] && document.config.cols_options[log.json.col.key][tag_id]' v-for='tag_id in log.removed' :key='tag_id' :style='{backgroundColor: (document.config.cols_options[log.json.col.key][tag_id].color || "#e4e4e4")}'
                  //- ) {{document.config.cols_options[log.json.col.key][tag_id].name}}
                  //- .d-flex.justify-content-end.flex-wrap(v-if='log.json.before && log.json.after')
                    span.ml-1.mr-1.before {{log.json.before}}
                    span
                      b-icon-arrow-right
                    span.ml-1.mr-1.after {{log.json.after}}
                  //- .d-flex.justify-content-end.flex-wrap(v-else-if='log.json.before && !log.json.after')
                    span.ml-1.mr-1.before {{log.json.before}}
                    span
                      b-icon-arrow-right
                    span.ml-1.mr-1.after (없음)
                  //- span(v-else)
                  //-   span.ml-1.mr-1.after {{log.json.after}}
                .flex-fill.text-right(v-else-if='log.json.col.format == "date" ')
                  .d-flex.justify-content-end.flex-wrap(v-if='log.json.before && log.json.after')
                    span.ml-1.mr-1.before(style='word-break: break-word; white-space: break-spaces')
                      cell-date(v-model='log.json.before')
                      //- template(v-if='log.json.before && log.json.before.date')
                        span.text {{log.json.before.date | date}}
                        span.text.ml-1(v-show='log.json.before.use_time == "Y"') {{log.json.before.time | time}}
                        span.mx-1.opacity-50(v-show='log.json.before.use_end_date == "Y"') ~
                        span.text {{log.json.before.end_date | date}}
                        span.text.ml-1(v-show='log.json.before.use_time == "Y"') {{log.json.before.end_time | time}}
                    span
                      b-icon-arrow-right
                    span.ml-1.mr-1.after
                      cell-date(v-model='log.json.after')
                      //- template(v-if='log.json.after && log.json.after.date')
                        span.text {{log.json.after.date | date}}
                        span.text.ml-1(v-show='log.json.after.use_time == "Y"') {{log.json.after.time | time}}
                        span.mx-1.opacity-50(v-show='log.json.after.use_end_date == "Y"') ~
                        span.text {{log.json.after.end_date | date}}
                        span.text.ml-1(v-show='log.json.after.use_time == "Y"') {{log.json.after.end_time | time}}
                  .d-flex.justify-content-end.flex-wrap(v-else-if='log.json.before && !log.json.after')
                    cell-date(v-model='log.json.before')
                    //- span.ml-1.mr-1.before(style='word-break: break-word; white-space: break-spaces')
                      template(v-if='log.json.before && log.json.before.date')
                        span.text {{log.json.before.date | date}}
                        span.text.ml-1(v-show='log.json.before.use_time == "Y"') {{log.json.before.time | time}}
                        span.mx-1.opacity-50(v-show='log.json.before.use_end_date == "Y"') ~
                        span.text {{log.json.before.end_date | date}}
                        span.text.ml-1(v-show='log.json.before.use_time == "Y"') {{log.json.before.end_time | time}}
                    span
                      b-icon-arrow-right
                    span.ml-1.mr-1.opacity-50 (없음)
                  .d-flex.justify-content-end.flex-wrap(v-else)
                    cell-date.after(v-model='log.json.after')
                    //- span.ml-1.mr-1.after(style='word-break: break-word; white-space: break-spaces')
                      template(v-if='log.json.after && log.json.after.date')
                        span.text {{log.json.after.date | date}}
                        span.text.ml-1(v-show='log.json.after.use_time == "Y"') {{log.json.after.time | time}}
                        span.mx-1.opacity-50(v-show='log.json.after.use_end_date == "Y"') ~
                        span.text {{log.json.after.end_date | date}}
                        span.text.ml-1(v-show='log.json.after.use_time == "Y"') {{log.json.after.end_time | time}}
                .flex-fill.text-right(v-else-if='log.json.col.format != "textarea" ')
                  .d-flex.justify-content-end.flex-wrap(v-if='log.json.before && log.json.after')
                    span.ml-1.mr-1.before(style='word-break: break-word; white-space: break-spaces') {{log.json.before}}
                    span
                      b-icon-arrow-right
                    span.ml-1.mr-1.after {{log.json.after}}
                  .d-flex.justify-content-end.flex-wrap(v-else-if='log.json.before && !log.json.after')
                    span.ml-1.mr-1.before(style='word-break: break-word; white-space: break-spaces') {{log.json.before}}
                    span
                      b-icon-arrow-right
                    span.ml-1.mr-1.opacity-50 (없음)
                  span(v-else)
                    span.ml-1.mr-1.after(style='word-break: break-word; white-space: break-spaces') {{log.json.after}}
              div(v-if='log.json.col.format == "textarea" ')
                //- pre {{log.diff}}
                .diff-body.bg-light.p-2.text-left.mt-1
                  span.diff-line(v-for='d in log.diff' :class='{added: d.added, removed: d.removed}') {{d.value}}

              //- .opacity-50(v-if='log.json.col.format != "textarea" ')
              .opacity-50
                //- small.float-right(v-b-tooltip.right :title='$options.filters.datetime(log.created_at)') {{ log.created_at | datetime_dow }}
                //- small.ml-3 {{log.username}}
                small {{log.username}}
                small.ml-3 {{ log.created_at | datetime_dow }}
                //- div(v-if='log.field == "note"')
          //- .mb-3
        .history-input.px-2
          form.pb-0(@submit.prevent='editing_note.id ? save_edit_note() : save_note()' ref='form_note')
            //- textarea-autosize.form-control.rounded.border-0.shadow(v-model='note' ref='input_note' placeholder='고객 메모 입력')
            textarea-autosize.form-control.bg-light(v-model='note' ref='input_note' placeholder='고객 메모 입력' style='max-height: 14rem')
            .pb-1
              template(v-if='editing_note.id')
                .d-flex.pt-1
                  button.btn.btn-default.btn-sm.px-2.py-1.shadow-sm.border.text-primary(type='submit') 수정
                  button.btn.btn-default.text-secondary#opt_date_note(type='button' v-b-tooltip title='날짜 입력' @click.stop='$root.$emit("bv::show::popover", `opt_date_note`)')
                    template(v-if='note_date && note_date.date')
                      span
                        span {{note_date.date | date}}
                        span.ml-1(v-show='note_date.use_time == "Y"') {{note_date.time | time}}
                      a.text-secondary.pl-1.ml-auto(href='#' @click.prevent.stop='note_date = {}' style='margin-right: -5px'): b-icon-x
                    template(v-else)
                      b-icon-calendar-date.opacity-50
                  button.btn.btn-default.btn-sm.ml-auto(type='button' @click='cancel_edit_note') 취소
              template(v-else)
                button.btn.btn-default.btn-sm.px-2.py-1.shadow-sm.border(type='submit') 추가
                button.btn.btn-default.text-secondary#opt_date_note(type='button' v-b-tooltip title='날짜 입력' @click.stop='$root.$emit("bv::show::popover", `opt_date_note`)')
                  template(v-if='note_date && note_date.date')
                    span
                      span {{note_date.date | date}}
                      span.ml-1(v-show='note_date.use_time == "Y"') {{note_date.time | time}}
                    a.text-secondary.pl-1.ml-auto(href='#' @click.prevent.stop='note_date = {}' style='margin-right: -5px'): b-icon-x
                  template(v-else)
                    b-icon-calendar-date.opacity-50
                button.btn.btn-default.text-secondary#opt_pipeline_note(type='button' v-b-tooltip title='파이프라인 추가' @click.stop='$root.$emit("bv::show::popover", `opt_pipeline_note`)')
                  b-icon-funnel.opacity-50

              b-popover(:ref='`opt_date_note`' :target='`opt_date_note`' triggers='click' no-fade placement='bottom' boundary='viewport' custom-class='popover-dropdown popover-dropdown-search-date' @hidden='note_date_select_hidden')
                form(@submit.prevent.stop='')
                  search-date(:col='{}' :document='document' mode='datetime' :value='note_date' @updated='note_date_did_updated')
              b-popover(:ref='`opt_pipeline_note`' :target='`opt_pipeline_note`' triggers='click' no-fade placement='bottom' boundary='viewport' custom-class='popover-dropdown popover-dropdown-pipeline')
                .d-flex
                  //- (style='width: 400px')
                  div.w-50
                    select.form-control.py-2.px-1(style='' v-model='current_pipeline_id' placeholder='파이프라인 추가' size=10)
                      option(v-for='p in $store.state.pipelines' :value='p.id' v-if='!pipeline_added[p.id]') {{p.name}}
                  div.w-50
                    select.form-control.py-2.px-1(style='' v-model='current_pipeline_status' v-if='current_pipeline_id' size=10)
                      template(v-for='p in $store.state.pipelines' v-if='p.id == current_pipeline_id')
                        option(v-for='s in p.config.status' :value='s.id') {{s.name}}
                button.btn.btn-primary.btn-block(style='border-radius: 0 0 3px 3px; margin-bottom: -3px' type='button' :disabled='!current_pipeline_status' @click='add_pipeline_status()') 추가
                //- dl.d-flex.col-list
                  dt 파이프라인
                  dd


</template>

<script>

import Dropdown from "@/components/Dropdown";
import DropdownContent from "@/components/DropdownContent";
import DropdownItem from "@/components/DropdownItem";
import DropdownBlock from "@/components/DropdownBlock";

// import SearchTag from "@/components/SearchTag";
import SearchTag2 from "@/components/SearchTag2";
import SearchDate from "@/components/SearchDate";

import * as hangul from 'hangul-js'
import moment from 'moment'

import { customAlphabet } from 'nanoid'
const nanoid = customAlphabet('123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz', 5)
// import TextareaAutosize from 'vue-textarea-autosize'

import { isEqual, debounce, cloneDeep, difference, isArray, compact, isObject } from "lodash"

import Tiptap from '@/components/Tiptap'

const Diff = require('diff')

import CellTag from '@/components/CellTag'
import CellDate from '@/components/CellDate'
import CellNumber from '@/components/CellNumber'

const copyToClipboard = (text) => {
  if (window.clipboardData && window.clipboardData.setData) {
      // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
      return window.clipboardData.setData("Text", text);

  }
  else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
      var textarea = document.createElement("textarea");
      textarea.textContent = text;
      textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
          return document.execCommand("copy");  // Security exception may be thrown by some browsers.
      }
      catch (ex) {
          console.warn("Copy to clipboard failed.", ex);
          return false;
      }
      finally {
          document.body.removeChild(textarea);
      }
  }
}

export default {
  name: 'document-record',
  props: ['property', 'document', 'document_record_id', 'relation', 'lookup_candidates_by_value', 'depth', 'did_open_relation_as_tab'],
  components: {
    Dropdown, DropdownContent, DropdownItem, DropdownBlock,
    SearchTag2, SearchDate,
    // TextareaAutosize,
    Tiptap,
    CellTag, CellDate, CellNumber,
  },
  computed: {
    session() {
      return this.$store.state.session
    },
    tags_ready() {
      return this.document && this.document.config && this.document.config.cols_options
    },
  },
  watch: {
    // '$store.state.documents'() {
    //   this.load()
    // },
    // 'document.id'() {
    //   this.load()
    // },
    'is_editing'() {
      this.$emit('editing', this.is_editing)
    },
    'document_record_id'() {
      console.log('document_record_id updated')
      this.load()
    },
  },
  data() {
    return {
      ENV: process.env.NODE_ENV,
      API_URL: process.env.VUE_APP_BASE_API_URL,
      done: false,
      saving: false,
      saving_text: '저장',
      // document: {},
      row: {},
      row_json_prev: '',

      dropdown_id_active: false,
      dropdown_id_loaded: false,

      group_rows: [],
      group_record_id: null,
      group_record_document: {},

      message_record_rows: [],
      message_record_loaded: false,

      row_tags: [],
      current_tag: null,
      show_tag_search: false,

      tab_index: 0,

      logs: [],
      logs_loaded: false,

      note: '',
      note_date: {},
      editing_note: {},

      opt_field_sub_showing: false,
      is_editing: false,
      is_expand: false,
      // is_editing: !false,
      expand_top: '0px',

      save_text: '저장',
      last_hover_col: null,

      selected_tab: '',
      relations: [],
      count_relations_by_document_id: {},
      selected_relation: {
        document_record_id: null,
      },

      pipelines: [],
      current_pipeline_id: null,
      current_pipeline_status: null,
      pipeline_added: {},

      state_focus_desc: 0,
      tabs: [],
    }
  },
  async mounted() {
    this.$emit('editing', this.is_editing)

    if (this.property?.id && this.document?.id && this.document_record_id) {
      this.load()
    }
    setTimeout(() => {
      if (this.is_editing) {
        this.focus_first_input()
      } else {

      }
      // this.$root.$emit("bv::show::popover", "opt_select_dpjSpE")

      // this.focus_desc()
    }, 300);

    // this.$on('dismiss', this._dismiss)
  },
  beforeDestroy() {

  },
  methods: {
    open_relation_as_new_tab(col, value) {
      const r = this.$router.resolve({
        name: 'document.view.record',
        params: {
          document_id: col.document_id,
          document_record_id: value.id,
        }
      })
      window.open(r.href, '_blank')
      return false
    },
    async open_relation_as_tab(col, value) {
      const id = Date.now()
      const tab = {
        id,
        col,
        value,
      }
      if (this.depth) {
        this.did_open_relation_as_tab(col, value)
        return
      }

      const visible = this.tabs.filter(e => {
        return e.col.document_id == col.document_id && e.col.document_colkey == col.document_colkey
      })[0]
      if (visible) {
        // just open. no need to initialize
        this.selected_tab = visible.id
        return
        // return ok()
      }

      const ok = () => {
        this.tabs.push(tab)
        this.selected_tab = id
      }

      // load rel of tab
      try {
        const eid_col = this.$store.state.documents_by_id[tab.col.document_id].config.cols.filter(e => e.format == 'relation')[0]
        console.log('eid_col', eid_col)
        if (!eid_col) {
          // ok
          ok()
        } else {
          const r = await this.$http.get(`/v1/property/${this.property.id}/views/documents/${eid_col.document_id}/find-lookups`, {
            params: {
              colkey: eid_col.document_colkey,
            }
          })
          const m = r?.data?.message || ''
          if (m != 'ok') {
            throw new Error('다른시트 내용 불러오기 실패: '+ r?.data?.message)
          }
          const lookup_candidates = r.data.rows
          const lookup_candidates_by_value = {}
          for (const e of lookup_candidates) {
            if (!lookup_candidates_by_value[e.value]) {
              lookup_candidates_by_value[e.value] = []
            }
            lookup_candidates_by_value[e.value].push(e)
          }

          // this.lookup_candidates = lookup_candidates
          tab.lookup_candidates_by_value = lookup_candidates_by_value
          ok()
        }
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    focus_desc() {
      this.state_focus_desc = Date.now()
    },
    _dismiss() {
      console.log('>>>dismiss')
      if (this.is_editing) {
        this.toggle_edit()
      }
    },
    async add_pipeline_status() {
      try {
        console.log(
          this.current_pipeline_id,
          this.current_pipeline_status,
        )

        const p = this.$store.state.pipelines_by_id[this.current_pipeline_id]
        if (!p) throw new Error('No pipe')
        console.log(p)

        const dup = this.pipelines.filter(e => +e.pipeline_id == +this.current_pipeline_id)
        console.log('>', dup, this.pipelines)
        if (dup.length) throw new Error('Duplicated on pipe')

        const s = p.config.status.filter(e => e.id == this.current_pipeline_status)[0]
        if (!s) throw new Error('No status at pipe')


        this.saving = true
        try {
          const r = await this.$http.post(`/v1/property/${this.property.id}/views/documents/${this.document.id}/records/row/${this.document_record_id}/pipelines`, {
            pipeline_id: this.current_pipeline_id,
            pipeline_status_name: s.name,
            pipeline_status_id: s.id,
          })
          if (r?.data?.message != 'ok') throw new Error('추가 실패. ' + r.data.message)

          this.load_pipelines()
          this.load_logs()
          this.$emit('updated_pipeline')
          // this.saving_text = '저장됨'

          this.current_pipeline_id = ''
          this.current_pipeline_status = ''
        } catch (error) {
          this.$modal.show('dialog', {
            title: '알림',
            text: error.message,
          })
        }
        setTimeout(() => { this.saving = false }, 300)

      } catch (error) {
        alert(error)
      }
    },
    async delete_pipeline_status(p) {
      if(!confirm('삭제하시겠습니까?')) return false
      try {
        this.saving = true
        try {
          const r = await this.$http.delete(`/v1/property/${this.property.id}/views/documents/${this.document.id}/records/row/${this.document_record_id}/pipelines/${p.pipeline_id}/records/${p.id}`, {

          })
          if (r?.data?.message != 'ok') throw new Error('삭제 실패. ' + r.data.message)

          this.load_pipelines()
          this.load_logs()
          this.$emit('updated_pipeline')
          // this.saving_text = '저장됨'
        } catch (error) {
          this.$modal.show('dialog', {
            title: '알림',
            text: error.message,
          })
        }
        setTimeout(() => { this.saving = false }, 300)

      } catch (error) {
        alert(error)
      }
    },
    async update_pipeline_status(p, new_state) {
      // if(!confirm('삭제하시겠습니까?')) return false
      try {
        this.saving = true
        try {
          const r = await this.$http.put(`/v1/property/${this.property.id}/views/documents/${this.document.id}/records/row/${this.document_record_id}/pipelines/${p.pipeline_id}/records/${p.id}`, {
            state: new_state,
          })
          if (r?.data?.message != 'ok') throw new Error('변경 실패. ' + r.data.message)

          this.load_pipelines()
          this.load_logs()
          this.$emit('updated_pipeline')
          // this.saving_text = '저장됨'
        } catch (error) {
          this.$modal.show('dialog', {
            title: '알림',
            text: error.message,
          })
        }
        setTimeout(() => { this.saving = false }, 300)

      } catch (error) {
        alert(error)
      }
    },
    async onPaste(e) {
      console.log('paste')

      const paste = (e.clipboardData || window.clipboardData).getData('text')

      console.log(paste)
      try {
        if (paste.startsWith('http')) {
          this.add_relation(paste)
        }
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: `실패`,
          variant: 'default',
          solid: true,
          toaster: 'b-toaster-bottom-right',
        })
      }
    },
    open_tab_new(relation) {
      const r = this.$router.resolve({
        name: 'document.view.record',
        params: {
          document_id: relation.target_document_id,
          document_record_id: relation.target_record_id,
        }
      })
      console.log(r.href)
      window.open(r.href, '_blank')
      return false
    },
    record_relation_did_updated() {
      console.log('record_relation_did_updated')
      this.load_relations()
    },
    select_tab(relation, subitem_given = false) {
      if (relation) {
        this.selected_tab = relation.id
        this.selected_relation = {}
        if (this.count_relations_by_document_id[relation.target_document_id] > 1 && !subitem_given) {
          this.selected_relation.show_list = true
          this.selected_relation.target_document_id = relation.target_document_id
        } else {
          this.selected_relation.show_list = false
          this.selected_relation.document = this.$store.state.documents_by_id[relation.target_document_id]
          this.selected_relation.target_document_id = relation.target_document_id
          this.selected_relation.document_record_id = relation.target_record_id
        }
        this.selected_relation.raw = relation
      } else {
        this.selected_tab = ''
        this.selected_relation = {}
        // this.selected_relation.document_record_id = null
      }
    },
    select_tab_item(relation) {
      this.select_tab(relation, true)
    },
    async add_relation(url = null) {
      if (url === null) {
        url = prompt('링크 주소 입력')
        if (!url) return
      } else {
        // if (!confirm(`추가할까요? \n주소:${url}`)) return false
      }

      this.saving = true
      try {
        const r = await this.$http.post(`/v1/property/${this.property.id}/views/documents/${this.document.id}/records/row/${this.document_record_id}/relations`, {
          url,
        })
        if (r?.data?.message != 'ok') throw new Error('추가 실패. ' + r.data.message)

        // this.load_logs()
        this.load_relations()
        // this.saving_text = '저장됨'
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      setTimeout(() => { this.saving = false }, 300)
    },
    async remove_relation() {
      if (!confirm('연결을 삭제할까요?')) return

      this.saving = true
      try {
        const r = await this.$http.delete(`/v1/property/${this.property.id}/views/documents/${this.relation.document_id}/relations/${this.relation.id}`, {

        })
        if (r?.data?.message != 'ok') throw new Error('삭제 실패. ' + r.data.message)

        // this.load_logs()
        // this.load_relations()
        this.$emit('updated_relation')
        // this.saving_text = '저장됨'
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      setTimeout(() => { this.saving = false }, 300)
    },
    focus_expand(colkey) {
      this.is_expand = true
      this.expand_top = -this.$refs[`detail-scroll`].scrollTop + 'px'
    },
    blur_expand(colkey) {
      this.is_expand = false
      this.expand_top = '0px'
    },
    isArray() {
      return isArray(...arguments)
    },
    tag_select_hidden(event) {
      this.save()
    },
    date_select_hidden(event) {
      this.save()
    },
    note_date_select_hidden(event) {
      // this.save()
    },
    set_last_hover_col(col) {
      console.log('last_hover_col')
      this.last_hover_col = col
    },
    copy_value(col) {
      // if (col.format == 'select') {
      //   let v = this.row.json[col.key].map(tag_id=> {
      //     return this.document.config.cols_options[col.key][tag_id].name
      //   })
      //   copyToClipboard(v.join(','))
      // }
      // else if (col.format == 'date') {
      //   let v = this.row.json[col.key].date
      //   if (this.row.json[col.key].time) {
      //     v += ' ' + this.row.json[col.key].time
      //   }
      //   if (this.row.json[col.key].use_end_date == 'Y') {
      //     v += ' ~ ' + this.row.json[col.key].end_date
      //     if (this.row.json[col.key].end_time) {
      //       v += ' ' + this.row.json[col.key].end_time
      //     }
      //   }
      //   copyToClipboard(v)
      // } else {
      //   copyToClipboard(this.row.json[col.key])
      // }
      copyToClipboard(this.row.json[col.key])
    },
    focus_first_input() {
      if (this.document?.config?.cols?.length) {
        for (const col of this.document.config.cols) {
          if (col.format[0] == '@' || ['text', 'textarea', 'number', 'check'].includes(col.format)) {
            const e = this.$refs[`input_field_${col.key}`]
            if (e && e[0]) {
              e[0].focus()
            }
            break
          }
        }
      }
    },
    remove_date(col) {
      console.log('>>>>>>>',)
      this.$set(this.row.json, col.key, '')
      this.$root.$emit("bv::show::popover", `opt_date_${col.key}`)
    },
    remove_tag(col, tagname) {
      console.log('>>>>>RM TA', this.row.json[col.key])
      const v = (this.row.json[col.key] || '').split(',').map(e => e.trim()).filter(e => e != tagname.trim())
      console.log(v)
      this.$set(this.row.json, col.key, v.join(','))
      // this.save_later()
      this.saving_text = '저장'
      this.$root.$emit("bv::show::popover", `opt_select_${col.key}`)
    },
    async tag_option_did_updated(col) {
      if (this.saving) return
      this.saving = true
      this.save_document_col_option()
      // this.save_document_col()
    },
    async tag_did_updated(col, tag) {
      // if (this.row.deleted_at) {
      //   console.log('cannot update deleted row')
      //   return
      // }

      // if (!this.row.json[col.key] || !isArray(this.row.json[col.key])) {
      //   // this.row.json[col.key] = []
      //   this.$set(this.row.json, col.key, [])
      // }
      if (!this.row.json[col.key]) this.row.json[col.key] = ''

      if (col.max) {
        this.row.json[col.key] = String(tag.name)
        this.blur_popover()
      } else {
        const search = ','+this.row.json[col.key]+','
        let v = ''
        console.log('>bef', this.row.json[col.key])
        if (search.includes(`,${tag.name},`)) {
          v = String(this.row.json[col.key] || '').split(',').map(e => e.trim()).filter(e => e != tag.name.trim())
          // this.row.json[col.key].push(String(tag.id))
        } else {
          v = String(this.row.json[col.key] || '').split(',').concat(tag.name)
          // this.row.json[col.key] = this.row.json[col.key].filter(e => {
          //   return e != tag.id
          // })
        }
        console.log('>>>>>', v )
        this.$set(this.row.json, col.key, compact(v).join(','))
      }
      this.save_document_col_option()
      this.saving_text = '저장'
      // this.save_later()
      // try {
      //   const r2 = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records/${this.row.id}/tags/actions/add`, {
      //     name: tag.name,
      //   })
      //   if (r2?.data?.message != 'ok') throw new Error(r2?.data?.message || '태그 저장실패')

      //   if (this.current_tag) {
      //     // await this.remove_tag(this.current_tag, true)
      //     this.current_tag = null
      //   }
      //   this.load_tags()
      //   this.$emit('tag_updated')
      //   // this.$modal.hide('tag')
      // } catch (error) {
      //   this.$modal.show('dialog', {
      //     title: '알림',
      //     text: error.message,
      //   })
      // }

    },
    async date_did_updated(col, date) {
      if (!this.row.json[col.key]) this.row.json[col.key] = {}
      let v = ''
      if (date.date) {
        v = date.date
      }
      // if (date.time) {
      //   v += ' ' + date.time
      // }
      this.row.json[col.key] = v
      // this.blur_popover()
    },
    async note_date_did_updated(col, date) {
      this.note_date = date
      // this.blur_popover()
    },
    toggle_edit(col = null) {
      this.is_editing = !this.is_editing
      if (this.is_editing) {
        setTimeout(() => {
          // if (this.last_hover_col) {
          //   const e = this.$refs[`input_field_${this.last_hover_col.key}`]
          //   if (e && e[0]) {
          //     e[0].focus()
          //   }
          if (col) {
            if (col.format == 'select') {
              this.$root.$emit("bv::show::popover", `opt_select_${col.key}`)
              const e = this.$refs[`input_field_${col.key}`]
              if (e && e[0]) {
                e[0].scrollIntoView({block: 'nearest'})
              }
            }
            else if (col.format == 'date') {
              this.$root.$emit("bv::show::popover", `opt_date_${col.key}`)
              const e = this.$refs[`input_field_${col.key}`]
              if (e && e[0]) {
                e[0].scrollIntoView({block: 'nearest'})
              }
            }
            else {
              const e = this.$refs[`input_field_${col.key}`]
              if (e && e[0]) {
                e[0].scrollIntoView({block: 'nearest'})
                e[0].focus()
              }
            }
          } else {
            this.focus_first_input()
          }
        }, 100);
      }
    },
    blur_popover() {
      // console.log('blur_popover')
      this.$root.$emit("bv::hide::popover")
    },
    opt_field_mouseenter() {
      this.opt_field_sub_showing = true
      this.$root.$emit("bv::show::popover", "opt_format")
    },
    opt_field_mouseleave() {
      this.opt_field_sub_showing = false
      setTimeout(() => {
        if (!this.opt_field_sub_showing) {
          this.$root.$emit("bv::hide::popover", "opt_format")
        }
      }, 1000)
    },
    copy_link() {
      const a = this.$router.resolve({
        name: 'document.view.record',
        params: {
          document_id: this.document.id,
          document_name: this.$options.filters.encodeReadableText(this.document.name),
        }
      })
      copyToClipboard(location.href.replace(location.pathname, '') + decodeURIComponent(a.href))
      // this.$modal.show('dialog', {
      //   title: '알림',
      //   text: '복사했습니다.',
      // })
    },
    edit_note(log) {
      this.editing_note = log
      this.note = log.json.note
      this.note_date = log.json.date
      this.$refs.input_note.$el.focus()
    },
    cancel_edit_note() {
      this.editing_note = {}
      this.note = ''
    },
    username(id) {
      return this.$store.state.users_by_id[id]?.name || '(이름없음)'
    },
    save_later() {
      return debounce(this.save, 3000, {
        leading: false,
        trailing: true,
      })()
    },
    click_save() {
      this.save(null, true)
    },
    async save($event, toggle_edit_mode = false) {
      if (this.row.deleted_at) {
        console.log('cannot update deleted row')
        return
      }
      // if (this.saving === true) {
      //   console.log('already in state of saving')
      //   if (toggle_edit_mode) {
      //     this.is_editing = false
      //   }
      //   return
      // }

      if (JSON.stringify(this.row.json) == this.row_json_prev) {
        // this.saving_text = '저장됨'
        this.saving = true
        setTimeout(() => {
          this.saving = false
          if (toggle_edit_mode) {
            this.is_editing = false
          }
        }, 300)
        return
      }
      this.saving = false
      if (toggle_edit_mode) {
        this.is_editing = false
      }

      // let text = []
      // for (const col of this.document.config.cols) {
      //   if (col.format == '@고객이름') {
      //     const v = this.row.json[col.key]
      //     if (v && v.length > 0) {
      //       text.push(this.row.json[col.key])
      //       break
      //     }
      //   }
      // }
      // if (text.length === 0) {
      //   for (const col of this.document.config.cols) {
      //     if (col.format == 'select') {
      //       if (this.row.json[col.key] && this.row.json[col.key].length) {
      //         const v = this.row.json[col.key].map(tagid => {
      //           return this.document.config.cols_options[col.key][tagid].name
      //         })
      //         text.push(v.join(', '))
      //       }
      //     }
      //     else if (col.format == 'date') {
      //       if (this.row.json[col.key] && this.row.json[col.key].date) {
      //         text.push(this.row.json[col.key].date)
      //       }
      //     }
      //     else {
      //       text.push(this.row.json[col.key])
      //     }
      //   }
      // }
      // text = text.filter(e => String(e || '').trim().length > 0).slice(0, 1).join(', ')

      this.saving = true
      try {
        console.log('try saving')

        const r = await this.$http.put(`/v1/property/${this.property.id}/views/documents/${this.document.id}/records/row/${this.document_record_id}`, {
          row: this.row.json,
          // preview_text: text,
        })
        if (r?.data?.message != 'ok') throw new Error('저장 실패. ' + r.data.message)

        const external_id = r?.data?.external_id
        if (external_id) {
          const eid_col = this.document.config.cols.filter(e => e.format == 'id')[0]
          if (eid_col) {
            this.row.json[eid_col.key] = external_id
          }
        }

        this.row_json_prev = JSON.stringify(this.row.json)

        this.$emit('updated', this.row.id)
        this.load_logs()
        this.saving_text = '저장됨'
        if (toggle_edit_mode) {
          this.is_editing = false
        }
        // setTimeout(() => {
        //   if (this.saving_text == '저장됨') {
        //     this.saving_text = '저장'
        //   }
        // }, 1000);
        this.update_title()
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      this.saving = false
      // setTimeout(() => { this.saving = false }, 300)
    },
    async save_page_name() {
      if (this.row.deleted_at) {
        console.log('cannot update deleted row')
        return
      }
      this.saving = true
      try {
        const r = await this.$http.post(`/v1/property/${this.property.id}/views/documents/${this.document.id}/records/row/${this.document_record_id}/page_name`, {
          name: this.row.name_text,
          // desc: this.row.desc_text,
        })
        if (r?.data?.message != 'ok') throw new Error('저장 실패. ' + r.data.message)
        this.load_logs()
        this.$emit('updated', this.row.id)

        setTimeout(() => { this.load_logs() }, 3000)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      setTimeout(() => { this.saving = false }, 300)
    },
    async save_page_desc() {
      if (this.row.deleted_at) {
        console.log('cannot update deleted row')
        return
      }
      this.saving = true
      try {
        const r = await this.$http.post(`/v1/property/${this.property.id}/views/documents/${this.document.id}/records/row/${this.document_record_id}/page_desc`, {
          // name: this.row.name_text,
          desc: this.row.desc_text,
        })
        if (r?.data?.message != 'ok') throw new Error('저장 실패. ' + r.data.message)
        this.load_logs()
        this.$emit('updated', this.row.id)

        setTimeout(() => { this.load_logs() }, 3000)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      setTimeout(() => { this.saving = false }, 300)
    },
    async save_note() {
      if (this.row.deleted_at) {
        console.log('cannot update deleted row')
        return
      }
      if (!this.note.length) return

      this.saving = true
      try {
        const r = await this.$http.post(`/v1/property/${this.property.id}/views/documents/${this.document.id}/records/row/${this.document_record_id}/note`, {
          note: this.note,
          note_date: this.note_date,
        })
        if (r?.data?.message != 'ok') throw new Error('저장 실패. ' + r.data.message)
        this.note = ''
        this.note_date = {}
        this.$emit('updated_note')
        this.load_logs()

        setTimeout(() => { this.load_logs() }, 3000)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      setTimeout(() => { this.saving = false }, 300)
    },
    async save_edit_note() {
      if (this.row.deleted_at) {
        console.log('cannot update deleted row')
        return
      }
      if (!this.note.length) return

      this.saving = true
      try {
        const r = await this.$http.put(`/v1/property/${this.property.id}/views/documents/${this.document.id}/records/row/${this.document_record_id}/note/${this.editing_note.id}`, {
          note: this.note,
          note_date: this.note_date,
        })
        if (r?.data?.message != 'ok') throw new Error('수정 실패. ' + r.data.message)
        this.note = ''
        this.note_date = {}
        this.editing_note = {}
        this.$emit('updated_note')
        this.load_logs()
        this.$modal.show('dialog', {
          title: '알림',
          text: '수정했습니다.',
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      setTimeout(() => { this.saving = false }, 300)
    },
    async update_format_document_col(col, value, opt) {
      try {
        // disable dedup
        if (value == '@고객아이디') {
          const exists = this.document.config.cols.filter(e => e.format == value)
          if (exists.length) {
            throw new Error(`[${exists[0].label}] 항목이 이미 [@고객아이디]로 지정되어 있습니다.`)
          }
        }
        if (value == '@고객연락처') {
          const exists = this.document.config.cols.filter(e => e.format == value)
          if (exists.length) {
            throw new Error(`[${exists[0].label}] 항목이 이미 [@고객전화번호]로 지정되어 있습니다.`)
          }
        }
        if (value == '@고객이메일') {
          const exists = this.document.config.cols.filter(e => e.format == value)
          if (exists.length) {
            throw new Error(`[${exists[0].label}] 항목이 이미 [@고객이메일]로 지정되어 있습니다.`)
          }
        }
        if (value == '@고객이름') {
          const exists = this.document.config.cols.filter(e => e.format == value)
          if (exists.length) {
            throw new Error(`[${exists[0].label}] 항목이 이미 [@고객이름]으로 지정되어 있습니다.`)
          }
        }
        col.format = value
        if (col.format == 'select') {
          if (opt == 'max=1') {
            col.max = 1
          } else {
            col.max = 0
          }
        }
        this.save_document_col()

      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async save_document_col_option() {
      this.saving = true
      try {
        // const v = this.document.config.cols_options || {}
        // const arr = {}
        // for (const colkey in v) {
        //   const o = v[colkey]
        //   const sub = []
        //   for (const tagid in o) {
        //     sub.push(o[tagid])
        //   }
        //   arr[colkey] = sub
        // }
        const document = Object.assign({}, this.document, {
          config: Object.assign({}, this.document.config, {
            cols_options: this.document.config.cols_options || {},
          })
        })
        const r = await this.$http.put(`/v1/property/${this.property.id}/views/documents/${this.document.id}`, document)
        if (r?.data?.message != 'ok') throw new Error('목록 항목옵션 저장 실패')

        await this.$store.dispatch('documents', this.property.id)
        this.update_title()
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      setTimeout(() => { this.saving = false }, 300)
    },
    async save_document_col() {
      this.saving = true
      try {
        const document = Object.assign({}, this.document, {
          config: Object.assign({}, this.document.config, {
            cols: this.document.config.cols,
            // last_col_id: this.document.config.last_col_id,
          })
        })
        const r = await this.$http.put(`/v1/property/${this.property.id}/views/documents/${this.document.id}`, document)
        if (r?.data?.message != 'ok') throw new Error('목록 항목 저장 실패')

        await this.$store.dispatch('documents', this.property.id)
        this.update_title()
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      setTimeout(() => { this.saving = false }, 300)
    },
    add_document_col() {
      this.is_editing = true
      const key = 'd' + nanoid()

      this.document.config.last_col_id++
      this.document.config.cols.push({
        key,
        label: '새로운 항목',
        format: 'text',
      })
      this.save_document_col()
      setTimeout(() => {
        this.$refs[`input_field_${key}`][0].focus()
      }, 0)
    },
    delete_document_col(col) {
      if (!confirm(`[${col.label}] 항목을 삭제하시겠습니까?`)) return false
      this.document.config.cols = this.document.config.cols.filter(e => {
        return e.key != col.key
      })
      this.save_document_col()
    },
    update_title() {
      let text = []
      for (const col of this.document.config.cols) {
        if (col.format == '@고객이름') {
          const v = this.row.json[col.key]
          if (v && v.length > 0) {
            text.push(this.row.json[col.key])
            break
          }
        }
      }
      if (text.length === 0) {
        for (const col of this.document.config.cols) {
          // if (col.format == 'select') {
          //   if (this.row.json[col.key] && this.row.json[col.key].length) {
          //     const v = this.row.json[col.key].map(tagid => {
          //       return this.document.config.cols_options[col.key][tagid].name
          //     })
          //     text.push(v.join(', '))
          //   }
          // }
          // else if (col.format == 'date') {
          //   if (this.row.json[col.key] && this.row.json[col.key].date) {
          //     text.push(this.row.json[col.key].date)
          //   }
          // }
          // else {
          //   text.push(this.row.json[col.key])
          // }
          text.push(this.row.json[col.key])
        }
      }
      text.push(`| ${this.document.name}`)
      setTimeout(() => {
        document.title = text.join(' ')
      }, 0);
    },
    async load() {
      // this.document = Object.assign({}, this.$store.state.documents_by_id[this.document.id])
      // if (!this.document.id) {
      //   throw new Error('해당 목록을 찾지 못했습니다.')
      // }
      try {
        const r = await this.$http.get(`/v1/property/${this.property.id}/views/documents/${this.document.id}/records/row/${this.document_record_id}`, {

        })
        this.row = r.data.row
        this.row_json_prev = JSON.stringify(this.row.json)

        // awxait this.load_tags()

        this.done = true
        this.$emit('loaded')

        this.load_logs()
        this.load_relations()
        this.load_pipelines()
        if (moment().diff(this.row.created_at, 'seconds') < 10) {
          this.is_editing = true
        }
        this.update_title()
        // this.load_message_record()
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async load_logs() {
      try {
        const r = await this.$http.get(`/v1/property/${this.property.id}/views/documents/${this.document.id}/records/${this.row.id}/logs`, {

        })
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '내역 가져오기 실패')
        this.logs = r.data.rows.map(e => {
          e.opened = false
          e.username = this.username(e.user_id)
          if (e.field == 'note') {

          }
          else if (e.field == 'pipeline') {

          }
          else if (e.field == 'page_name') {

          }
          else if (e.field == 'page_desc') {
            e.diff = Diff.diffWords(e.json.before, e.json.after)
          }
          else {
            e.username_postfix = hangul.endsWithConsonant(e.username) ? '이' : '가'
            e.col_postfix = hangul.endsWithConsonant(e.json.col.label) ? '을' : '를'
            // e.before_postfix = hangul.endsWithConsonant(e.json.before) ? '을' : '를'
            // e.after_postfix = hangul.endsWith(e.json.after, 'ㄱ') ? '으로' : '로'
            if (e.json.col.format == 'textarea') {
              e.diff = Diff.diffWords(e.json.before, e.json.after)
            }
          }
          if (e.json?.col?.format == 'select') {
            // e.is_changed = isEqual(e.json.before, e.json.after)
            if (isArray(e.json.after)) {
              // fill
              const added = difference(e.json.after, e.json.before).map(tagid => {
                if (this.document.config.cols_options
                  && this.document.config.cols_options[e.json.col.key]
                  && this.document.config.cols_options[e.json.col.key][tagid]) {
                    let name = this.document.config.cols_options[e.json.col.key][tagid].name
                    return name.replace(/\,/g, '')
                } else {
                  return ''
                }
              })
              e.added = added.join(',')
              const removed = difference(e.json.before, e.json.after).map(tagid => {
                if (this.document.config.cols_options
                  && this.document.config.cols_options[e.json.col.key]
                  && this.document.config.cols_options[e.json.col.key][tagid]) {
                    let name = this.document.config.cols_options[e.json.col.key][tagid].name
                    return name.replace(/\,/g, '')
                } else {
                  return ''
                }
              })
              e.removed = removed.join(',')
            } else {
              e.added = difference(e.json.after.split(','), e.json.before.split(',')).join(',')
              e.removed = difference(e.json.before.split(','), e.json.after.split(',')).join(',')
            }
            // e.marked = e.json.
          }
          if (e.json?.col?.format == 'date') {
            if (isObject(e.json.after)) {
              e.json.after = compact([e.json.after.date, e.json.after.time]).join(' ')
            }
            if (isObject(e.json.before)) {
              e.json.before = compact([e.json.before.date, e.json.before.time]).join(' ')
            }
          }
          return e
        })

        this.logs_loaded = true
        setTimeout(() => {
          if (!this.$refs.logs) return
          const items = this.$refs.logs.querySelectorAll('.item-history')
          if (items.length > 3) {
            items[items.length-1].scrollIntoView({block: 'nearest'})
          }
        }, 100);
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async load_relations() {
      try {
        const r = await this.$http.get(`/v1/property/${this.property.id}/views/documents/${this.document.id}/records/row/${this.row.id}/relations`, {

        })
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '내역 가져오기 실패')
        const c = {}
        this.relations = r.data.rows.map(e => {
          if (!c[e.target_document_id]) {
            c[e.target_document_id] = 0
            e.is_first = true
          } else {
            e.is_first = false
          }
          c[e.target_document_id]++
          return e
        })

        this.count_relations_by_document_id = c

        // this.logs_loaded = true
        // setTimeout(() => {
        //   console.log(this.$refs.logs)
        //   const items = this.$refs.logs.querySelectorAll('.item-history')
        //   if (items.length > 3) {
        //     items[items.length-1].scrollIntoView({block: 'nearest'})
        //   }
        // }, 100);
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async load_pipelines() {
      try {
        const r = await this.$http.get(`/v1/property/${this.property.id}/views/documents/${this.document.id}/records/row/${this.row.id}/pipelines`, {

        })
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '내역 가져오기 실패')
        this.pipeline_added = {}
        this.pipelines = r.data.rows.map(e => {
          this.pipeline_added[e.pipeline_id] = true
          return e
        })
        await this.$store.dispatch('pipelines', this.property.id)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async load_message_record() {
      try {
        const r = await this.$http.get(`/v1/property/${this.property.id}/views/documents/${this.document.id}/record-ids/${this.row.id}/message-records`, {

        })
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '내역 가져오기 실패')
        this.message_record_rows = r.data.rows.map(e => {
          e.opened = false
          return e
        })

        this.message_record_loaded = true
        setTimeout(() => {
          console.log(this.$refs.message_history)
          const items = this.$refs.message_history.querySelectorAll('.item-history')
          if (items.length > 3) {
            items[items.length-1].scrollIntoView({block: 'nearest'})
          }
        }, 100);
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    // async submit() {
    //   if (this.row.deleted_at) {
    //     console.log('cannot update deleted row')
    //     return
    //   }
    //   this.saving = true
    //   this.saving_text = '저장 중'
    //   try {
    //     const r = await this.$http.put(`/v1/property/${this.property.id}/views/documents/${this.document.id}/records/row/${this.document_record_id}`, {
    //       row: this.row.json,
    //     })
    //     if (r?.data?.message != 'ok') throw new Error('저장 실패. ' + r.data.message)

    //     const external_id = r.data.external_id

    //     // if (this.row.external_id != r.data.external_id) {
    //     //   if (!this.hide_customer) {
    //     //     this.$router.push({
    //     //       path: `/property/${this.property.id}/customer/${this.document.id}/${this.$options.filters.encodeText(this.document.name)}/${r.data.id}/${encodeURIComponent(r.data.external_id)}`,
    //     //     })
    //     //   }
    //     // } else {
    //     //   this.load()
    //     // }
    //     this.load()

    //     this.saving_text = '저장됨'

    //     // if (!this.hide_customer) {
    //     //   this.$emit('updated')
    //     // }
    //     this.$emit('updated', {
    //       external_id,
    //     })
    //   } catch (error) {
    //     this.$modal.show('dialog', {
    //       title: '알림',
    //       text: error.message,
    //     })
    //   }
    //   setTimeout(() => {
    //     this.saving = false
    //   }, 300);
    // },
    async remove() {
      if (this.row.deleted_at) {
        console.log('cannot update deleted row')
        return
      }
      try {
        if(!confirm('삭제하시겠습니까? 휴지통에 보관됩니다.')) return false

        const r = await this.$http.delete(`/v1/property/${this.property.id}/views/documents/${this.document.id}/records/row/${this.document_record_id}`)
        if (r?.data?.message != 'ok') throw new Error('저장 실패. ' + r.data.message)
        // this.load()

        this.$emit('updated')
        // this.$router.push({
        //   path: `/property/${this.property.id}/customer/${this.document.id}/${this.$options.filters.encodeText(this.document.name)}`,
        // })
        this.$modal.show('dialog', {
          title: '알림',
          text: '삭제했습니다.',
        })
        this.load()
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      this.saving = false
    },
    async remove_undo() {
      if (!this.row.deleted_at) {
        console.log('cannot recover undeleted row')
        return
      }
      try {
        if(!confirm('삭제된 내역을 복구 하시겠습니까?')) return false

        const r = await this.$http.post(`/v1/property/${this.property.id}/views/documents/${this.document.id}/records/row/${this.document_record_id}/actions/undo_delete`)
        if (r?.data?.message != 'ok') throw new Error('복구 실패. ' + r.data.message)
        // this.load()

        this.$emit('updated')
        // this.$router.push({
        //   path: `/property/${this.property.id}/customer/${this.document.id}/${this.$options.filters.encodeText(this.document.name)}`,
        // })
        this.$modal.show('dialog', {
          title: '알림',
          text: '복구했습니다.',
        })
        this.load()
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      this.saving = false
    },
  },
}
</script>
