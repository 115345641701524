<style lang="sass" scoped>
.scroll
  height: 300px
  // min-height: 300px
  overflow: scroll
  // max-height: 300px
.item
  border-left: solid 2px #fff
  font-size: 14px
  font-weight: 500
  cursor: pointer
  &.active
    border-left: solid 2px #007bff
  // &:hover
  //   border-left: solid 2px #007bff
  //   background-color: #fafafa
  //   cursor: pointer
  &.checked
    // border-left: solid 2px #fafafa
    border-left: solid 2px #fafafa
    background-color: #fafafa
    .check
      visibility: visible
  &.selected
    border-left: solid 2px #007bff
    // background-color: #fafafa
    background-color: #f0f0f0

.query
  font-size: 14px
  // padding: 2rem 1rem
.color-label
  display: inline-block
  width: 1rem
  height: 1rem
  float: left
  // color: transparent
.handle-grip
  opacity: 0
  margin-left: -5px
  cursor: move

.item
  &:hover
    .handle-grip
      opacity: 1
.tag-added
  background-color: mix(#fff, #B5EFDB, 50%)
  color: mix(#000, #B5EFDB, 50%)
.check
  // text-align: center
  vertical-align: top
  width: 28px
  display: inline-block
  font-size: 1.3rem
  line-height: 0.5rem
  visibility: hidden
.tag-item
  word-break: break-all
  max-width: 185px
  display: inline-block
  padding: 1px 5px
</style>
<template lang="pug">
div.text-right(:key='ts')
  span {{value | number}}
  span.float-left(v-if='!isValid' style='color: #999; margin-right: -30px')
    b-icon-exclamation-triangle-fill(style='color: #ffa801')
    |  오류
</template>

<script>

import { isString, isArray, isNumber } from 'lodash'

export default {
  name: "CellTag",
  props: ['value', 'col'],
  components: {

  },
  watch: {
    // value() {
    //   this.ts = Date.now()
    // },
    // col: {
    //   handler() {
    //     this.ts = Date.now()
    //   },
    //   deep: true,
    // },
  },
  computed: {
    isValid() {
      return isFinite(+this.value)
    }
  },
  data() {
    return {
      ts: Date.now(),
      // invalid: false,
    }
  },
  mounted() {

  },
  methods: {

  },
};
</script>
