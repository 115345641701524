<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
.subtitle
  font-weight: bold
  font-size: 1rem
  color: #777
.ghost
  opacity: 0.5
  // background: #c8ebfb
  // background-color: #fff !important
.dragging
  .flip-list-move
    transition: transform 0.5s
  .btn
    cursor: ns-resize
    background-color: #fff !important

.table-preview
  .sticky-header
    position: sticky
    top: -1px
    background-color: #f0f0f0
    z-index: 1000
    // outline: solid 1px #ccc
    // border-top: solid 1px #ccc
    box-shadow: inset 0px 0px 0px 1px rgba(0,0,0, 0.1), 0 0.3rem 0.5rem rgba(0, 0, 0, 0.05)
    // border-bottom: 0
    color: #333 !important
  tbody
    tr
      td
        color: #555
        &.text-pre
          white-space: pre-line
.block-html
  // opacity: 0.3
  // transition: opacity 0.2s
  // &:hover
  //   opacity: 1

.table-project
  width: 3000px
  border-color: #d4d4d4
  font-size: 14px
  border-width: 1px 0px 1px 0px
  th, .th
    padding: 0.5rem
    &.header
      color: #999
      font-weight: normal
      &:hover
        background-color: #f0f0f0
        cursor: pointer
  .th-check
    width: 40px
    padding: 7px 0 7px 6px
    text-align: center
  .td-check
    width: 40px
    padding: 7px 0px 7px 6px
    text-align: center
  tbody
    tr
      td
        padding: 0.5rem

      &.hover
        cursor: pointer
        .hover-visible
          visibility: hidden
        &:hover
          box-shadow: 0px 2px 5px -2px rgba(0,0,0,0.5)
          .hover-visible
            visibility: visible

        &.row-selected
          background-color: mix(#007bff, #f0f0f0, 8%)

          td
            border-top-color: mix(#007bff, #f0f0f0, 20%)
            border-left-color: mix(#007bff, #f0f0f0, 20%) !important
        &.row-open
          box-shadow: 0px 2px 5px -2px rgba(0,0,0,0.5)
      &.row-edited
        background-color: mix(#f1c40f, #fff, 10%)

        td
          // border-top-color: mix(#f1c40f, #fff, 20%)
          // border-left-color: mix(#f1c40f, #fff, 20%) !important
        .input-cell
          background-color: mix(#f1c40f, #fff, 10%)


      .td
        overflow: hidden
        span
          width: inherit
          display: block
          overflow: hidden
          white-space: nowrap
</style>
<template lang="pug">
div
  div.async(:class='{done: done}')
    form.form(@submit.prevent='submit()')
      h5.title 1. 파일을 선택하거나 내용을 붙여넣기 해주세요.
      .form-group
        b-form-file(v-model='file' placeholder='엑셀 파일을 선택해주세요.' accept='.csv, .tsv, .xls, .xlsx')
      .form-group
        label.d-block.text-center OR
        textarea.form-control(rows=5 v-model='import_data' placeholder='(예시)\n항목\t항목\t\n내용\t내용\n내용\t내용')
        span.text-muted 엑셀 내용을 복사해서 여기에 붙여넣기 해주세요.

      .form-group
        h5.title 2. 가져올 항목과 내용을 확인해주세요.
        button.btn.btn-light.w-50.py-2.shadow-sm.border.text-primary.ml-4(type='button' @click='blur_import_data') 클릭하여 미리보기

        //- pre {{tables}}
        //- .mb-4
        div(style='margin: 0 -1.5rem;')
          div(v-for='(table, idx) in tables' style='overflow: scroll; width: 100%; padding: 2rem')
            small {{table.filename}} {{table.sheetname}}
            div.d-flex.table-project.border-top.position-sticky.bg-white.border-bottom(style='width: max-content;')
              div.border-left.header.handle.th(
                v-for='col in table.cols'
                :style='{width: (200)+"px"}'
                :id='`opt_field_table_${col.key}`'
                @click.stop='$root.$emit("bv::hide::popover"); $root.$emit("bv::show::popover", `opt_field_table_${col.key}`)'
              )
                | {{col.label}}
                span.float-right
                  span(v-if='col.format == `text`')
                    b-icon-fonts.mr-2.opacity-50
                    span 텍스트
                  span(v-else-if='col.format == `textarea`')
                    b-icon-text-left.mr-2.opacity-50
                    span 긴 텍스트
                  span(v-else-if='col.format == `number`')
                    b-icon-hash.mr-2.opacity-50
                    span 숫자
                  span(v-else-if='col.format == `check`')
                    b-icon-check-square.mr-2.opacity-50
                    span 체크
                  span(v-else-if='col.format == `select` && col.max === 1')
                    b-icon-caret-down-fill.mr-2.opacity-50
                    span 선택
                  span(v-else-if='col.format == `select` && col.max !== 1')
                    b-icon-list-ul.mr-2.opacity-50
                    span 다중 선택
                  span(v-else-if='col.format == `date`')
                    b-icon-calendar-date.mr-2.opacity-50
                    span 날짜
                  span(v-else-if='col.format == `@고객이름`')
                    b-icon-type.mr-2.opacity-50
                    span 고객이름
                  span(v-else-if='col.format == `@고객아이디`')
                    b-icon-person-bounding-box.mr-2.opacity-50
                    span 고객아이디
                  span(v-else-if='col.format == `@고객이메일`')
                    b-icon-at.mr-2.opacity-50
                    span 고객이메일
                  span(v-else-if='col.format == `@고객연락처`')
                    b-icon-telephone-fill.mr-2.opacity-50
                    span 고객전화번호
                  span(v-else)
                    span {{col.format}}
                b-popover(:ref='`opt_field_table_${col.key}`' :target='`opt_field_table_${col.key}`' triggers='click' placement='bottom' no-fade custom-class='popover-dropdown')
                  div
                    span.dropdown-label.px-2 필드명
                    .p-2
                      input.form-control.form-control-sm(type='text' v-model.lazy='col.label' :ref='`opt_field_input_table_${col.key}`')
                    .mt-2
                    span.dropdown-label.px-2 필드 타입
                    div
                      button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='update_format(col, "text")')
                        b-icon-fonts.mr-2.opacity-50
                        span 텍스트
                        b-icon-check(v-show='col.format == `text`')
                      button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='update_format(col, "textarea")')
                        b-icon-text-left.mr-2.opacity-50
                        span 긴 텍스트
                        b-icon-check(v-show='col.format == `textarea`')
                      button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='update_format(col, "number")')
                        b-icon-hash.mr-2.opacity-50
                        span 숫자
                        b-icon-check(v-show='col.format == `number`')
                      button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='update_format(col, "check")')
                        b-icon-check-square.mr-2.opacity-50
                        span 체크
                        b-icon-check(v-show='col.format == `check`')
                      button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='update_format(col, "select", "max=1")')
                        b-icon-caret-down-fill.mr-2.opacity-50
                        span 선택
                        b-icon-check(v-show='col.format == `select` && col.max === 1')
                      button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='update_format(col, "select")')
                        b-icon-list-ul.mr-2.opacity-50
                        span 다중 선택
                        b-icon-check(v-show='col.format == `select` && col.max !== 1')
                      button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='update_format(col, "date")')
                        b-icon-calendar-date.mr-2.opacity-50
                        span 날짜
                        b-icon-check(v-show='col.format == `date`')
                      .border-bottom.opacity-50.mb-2
                      span.dropdown-label.px-2.pt-2 고객
                      button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='update_format(col, "@고객이름")')
                        b-icon-type.mr-2.opacity-50
                        span 이름
                        b-icon-check(v-show='col.format == `@고객이름`')
                      button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='update_format(col, "@고객아이디")')
                        b-icon-person-bounding-box.mr-2.opacity-50
                        span 아이디
                        b-icon-check(v-show='col.format == `@고객아이디`')
                      button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='update_format(col, "@고객이메일")')
                        b-icon-at.mr-2.opacity-50
                        span 이메일
                        b-icon-check(v-show='col.format == `@고객이메일`')
                      button.btn.btn-default.btn-block.mt-0.rounded-0.link.text-left.mb-0(type='button' @click='update_format(col, "@고객연락처")')
                        b-icon-telephone-fill.mr-2.opacity-50
                        span 전화번호
                        b-icon-check(v-show='col.format == `@고객연락처`')

                    .px-2.pb-2(v-show='col.format == "check" ')
                      input.form-control.form-control-sm(type='text' v-model.lazy='col.description' placeholder='설명')
            table.bg-white.table.table-project.border-bottom(style='width: max-content; margin-right: 2rem')
              tbody
                tr.hover(v-for='row in table.rows')
                  td.td.border-left(v-for='col in table.cols' :style='{width: (200)+"px", maxWidth: (200)+"px"}')
                    template(v-if='col.format == "select"')
                      cell-tag(v-model='row[col._label]' :col='col')
                    template(v-else-if='col.format == "date"')
                      cell-date(v-model='row[col._label]' :col='col')
                    template(v-else-if='col.format == "number"')
                      cell-number(v-model='row[col._label]' :col='col')
                    span(v-else) {{row[col._label]}}
            label {{table.count}}건



      //- .form-group.pt-4
        label.d-block 내용 확인
          button.btn.btn-light.text-primary.ml-2(type='button' @click='blur_import_data') 새로고침
        .bg-light.p-4.async(:class='{done: (step == 3)}')
          strong.text-dark {{prepare_text}}
          pre.pt-4.pb-0.mb-0(v-if='results.errors.length')
            ul
              li(v-for='e in results.errors')
                strong {{e.message}}
                p {{e.code}}:{{e.type}}


      .pb-4
      .form-group.async(:class='{done: prepared}' v-if='tables && tables[0] && tables[0].count')
        .bg-light.rounded.m-2.p-4
          label.d-block 가져오기 진행률
            span.ml-2.float-right ({{count_submit}}/{{tables[0].count}})
          b-progress.shadow-sm(class="mt-2" :max="tables[0].count" show-value)
            b-progress-bar(:value="count_submit" variant="primary")
      .pb-4
      .form-group
        h5.title 3. 데이터
          strong.px-1.text-primary {{(tables && tables[0] && tables[0].count) || 0}}건
          | 을 저장합니다.
        button.btn.btn-primary.w-50.py-2.shadow-sm.border.ml-4(type='submit' :disabled='saving || !prepared') {{saved_text}}
      .clearfix
      .pb-4


</template>

<script>

const Papa = require('papaparse')
const xlsx = require('xlsx')

import { customAlphabet } from 'nanoid'
const nanoid_colkey = customAlphabet('123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz', 5)
import { isString, keyBy } from 'lodash'

import CellTag from '@/components/CellTag'
import CellDate from '@/components/CellDate'
import CellNumber from '@/components/CellNumber'

let FULL_ROWS;

export default {
  name: 'index',
  props: ['property_id', 'document_id', 'document'],
  components: {
    CellTag, CellDate, CellNumber,
  },
  computed: {

  },
  watch: {

  },
  data() {
    return {
      done: false,
      saving: false,
      prepared: false,
      saved_text: '저장하기',
      apply_upsert: 'N', // external_id로 업데이트
      step: 1,

      file: null,
      import_data: '',

      headers: [],
      cols: [],
      count_submit: 0,
      tables: [],
//       tables: [
//   {
//     "filename": "엑셀 테스트 .csv",
//     "sheetname": "Sheet1",
//     "rows": [
//       {
//         "고객이름": "김찹쌀",
//         "전화번호": "1041029921",
//         "이메일": "eces92@gmail.com",
//         "분류": "고객사, 영업팀",
//         "연락일": "2021-08-05"
//       },
//       {
//         "고객이름": "김팔두",
//         "분류": "개발팀, 연구소",
//         "연락일": "2021-08-01"
//       }
//     ],
//     "cols": [
//       {
//         "label": "고객이름",
//         // "format": "text",
//         "format": "number",
//         "key": "dWq8Ub"
//       },
//       {
//         "label": "전화번호",
//         "format": "text",
//         "key": "dmyDKd"
//       },
//       {
//         "label": "이메일",
//         "format": "text",
//         "key": "dZSSvJ"
//       },
//       {
//         "label": "분류",
//         "format": "text",
//         "key": "dLRmdH"
//       },
//       {
//         "label": "연락일",
//         "format": "date",
//         "key": "dWBBpY"
//       }
//     ]
//   }
// ]
    }
  },
  async mounted() {
    this.load()
  },
  methods: {
    update_format(col, format, opt) {
      col.format = format
      if (col.format == 'select') {
        if (opt == 'max=1') {
          this.$set(col, 'max', 1)
        } else {
          this.$set(col, 'max', 0)
        }
        // console.log({opt, col })
      }
      this.$root.$emit("bv::hide::popover")
    },
    blur_import_data() {
      if (this.file) {
        return this.preview_file()
      }
      if (this.import_data.length) {
        return this.preview()
      }
      this.step = 1
      this.prepared = false
      this.$modal.show('dialog', {
        title: '알림',
        text: '내용을 입력해주세요.',
      })
    },
    load() {
      // this.document = Object.assign({}, this.$store.state.documents_by_id[this.document_id])
      // if (!this.document.id) {
      //   this.error = '해당 목록을 찾지 못했습니다.'
      //   return
      // }

      if (!this.document.config) {
        this.document.config = {}
      }
      if (!this.document.config.cols) {
        this.document.config.cols = []
      }
      if (this.document.config.last_col_id) {
        this.last_col_id = this.document.config.last_col_id
      }
      const prev_cols = this.cols.slice()
      const prev_cols_by_key = {}
      for (const col of prev_cols) {
        prev_cols_by_key[col.key] = col
      }
      // console.log('>>', prev_cols_by_key)
      this.cols = this.document.config.cols.slice()

      for (const col of this.cols) {
        const f = prev_cols_by_key[col.key]
        if (f) {
          col.value = f.value
        }
      }

      this.done = true
    },
    preview() {
      let text = this.import_data.trim()
      if (!text || text.length === 0) {
        this.$modal.show('dialog', {
          title: '알림',
          text: ' 내용이 없습니다.',
        })
        this.step = 1
        return
      }
      if (text.includes('\n')) {
        const parts = text.split('\n')
        if (parts[1].includes('\t') && parts[0].includes(',')) {
          // console.log('re[p')
          parts[0] = parts[0].replace(/\,/g, '\t')
        }
        text = parts.join('\n')
        // console.log(text)
      }

      let config = {}

      const r = Papa.parse(text, config)

      this.results = r
      if (this.results.errors.length) {
        this.$modal.show('dialog', {
          title: '알림',
          text: this.prepare_text,
        })
        this.prepared = false
        this.step = 1
        return
      }
      if (this.results.data.length === 1) {
        this.$modal.show('dialog', {
          title: '알림',
          text: '헤더와 내용, 최소 2줄 이상의 내용을 입력해주세요.',
        })
        this.prepared = false
        this.step = 1
        return
      }

      if (this.results && this.results.data) {
        const parsed_headers = this.results.data[0]
        const json = this.results.data.slice(1)
        // console.log('>>>>>>', json)
        let cols = []
        if (json[0]) {
          cols = parsed_headers.map(label => {
            return {
              label,
              _label: label,
              format: 'text',
              key: 'd' + nanoid_colkey(),
            }
          })
        }
        this.tables.push({
          filename: '',
          sheetname: '붙여넣기 내용',
          rows: json.slice(0, 5).map(row => {
            const v = {}
            for (const i in cols) {
              v[cols[i].label] = row[i]
            }
            return v
          }),
          cols,
          count: json.length,
        })
      }

      this.prepared = true


      this.step = 3
    },
    preview_file() {
      try {
        if (!this.file || this.file.length == 0) {
          throw new Error('파일을 선택해주세요')
        }

        this.tables = []

        const handle_file = (file) => {
          const reader = new FileReader()
          reader.onload = (e) => {
            // console.log(e.target.result)
            let data, wb
            if (isString(e.target.result)) {
              // csv
              data = e.target.result
              wb = xlsx.read(data, {type: 'string', raw: true})
            } else {
              // excel
              data = new Uint8Array(e.target.result)
              wb = xlsx.read(data, {type: 'array', raw: true})
            }
            if (wb.Sheets.length === 0) throw new Error('sheets length error')

            for (const wsname in wb.Sheets) {
              if (this.tables.length) break

              const ws = wb.Sheets[wsname]
              const json = xlsx.utils.sheet_to_json(ws)
              const cols = []
              if (json[0]) {
                const labels = {}
                for (const row of json) {
                  for (const k of Object.keys(row)) {
                    labels[k] = true
                  }
                }
                console.log(labels)
                cols = Object.keys(labels).map(label => {
                  return {
                    label,
                    _label: label,
                    format: 'text',
                    key: 'd' + nanoid_colkey(),
                  }
                })
              }
              this.tables.push({
                filename: file.name,
                sheetname: wsname,
                rows: json.slice(0, 5),
                cols,
                count: json.length,
              })
              FULL_ROWS = json
            }
            // console.log('>>>', wb)
            this.prepared = true

          }
          if (file.name.endsWith('csv')) {
            reader.readAsText(file)
          } else {
            reader.readAsArrayBuffer(file)
          }
        }
        // for (const f of this.file) {
        //   handle_file(f)
        // }
        handle_file(this.file)

        // console.log(this.file)

      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error,
        })
      }
    },
    async submit() {
      try {
        this.saving = true

        this.saved_text = '저장 중'

        const table = this.tables[0]

        if(!confirm(`${table.count}건을 추가할까요?`)) throw new Error('취소되었습니다.')

        const document = Object.assign({}, this.document, {
          config: Object.assign({}, this.document.config, {
            cols: table.cols,
            use_external_id: 'none',
          })
        })
        const r = await this.$http.put(`/v1/property/${this.property_id}/views/documents/${this.document_id}`, document)
        if (r?.data?.message != 'ok') throw new Error('목록 항목 저장 실패 (고객데이터 추가전에 해당목록 항목을 먼저 저장이 필요합니다.)')

        const cols_by_label = keyBy(table.cols, '_label')

        for (const d of FULL_ROWS) {
          const row = {}
          for (const label in d) {
            const col = cols_by_label[label]
            if (!col) alert(JSON.stringify([col, label]))
            row[col.key] = String(d[label] || '')
          }
          const r = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records`, {
            row,
          }, {
            params: {
              apply_upsert: this.apply_upsert,
            }
          })
          if (r?.data?.message != 'ok') throw new Error(`고객 저장 실패 (${this.count_submit+1}/${table.count}) ` + r?.data?.message)
          // console.log(row)
          this.count_submit = this.count_submit+1
        }
        await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.document_id}/actions/fill_cols_options`)

        this.saved_text = '저장하고 테이블을 준비중입니다 ...'
        setTimeout(() => {
          this.saved_text = '완료'
          this.$store.dispatch('documents', this.property_id)

          setTimeout(() => {
            this.saving = false
            this.$emit('did_saved')
          }, 300);
        }, 3000);
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
        this.saved_text = '저장하기'
        this.saving = false
      }
    },
  },
}
</script>
