<style lang="sass" scoped>
.scroll
  height: 300px
  // min-height: 300px
  overflow: scroll
  // max-height: 300px
.item
  border-left: solid 2px #fff
  font-size: 14px
  font-weight: 500
  cursor: pointer
  &.active
    border-left: solid 2px #007bff
  // &:hover
  //   border-left: solid 2px #007bff
  //   background-color: #fafafa
  //   cursor: pointer
  &.checked
    // border-left: solid 2px #fafafa
    border-left: solid 2px #fafafa
    background-color: #fafafa
    .check
      visibility: visible
  &.selected
    border-left: solid 2px #007bff
    // background-color: #fafafa
    background-color: #f0f0f0

.query
  font-size: 14px
  // padding: 2rem 1rem
.color-label
  display: inline-block
  width: 1rem
  height: 1rem
  float: left
  // color: transparent
.handle-grip
  opacity: 0
  margin-left: -5px
  cursor: move

.item
  &:hover
    .handle-grip
      opacity: 1
.tag-added
  background-color: mix(#fff, #B5EFDB, 50%)
  color: mix(#000, #B5EFDB, 50%)
.check
  // text-align: center
  vertical-align: top
  width: 28px
  display: inline-block
  font-size: 1.3rem
  line-height: 0.5rem
  visibility: hidden
.tag-item
  word-break: break-all
  max-width: 185px
  display: inline-block
  padding: 1px 5px
</style>
<template lang="pug">
div
  .mx-1(@keydown.enter.prevent='')
    input.form-control.query(
      type='text' v-model='q' ref='input'
      :placeholder='placeholder'
      @input='q = $event.target.value'
      @keydown.down.prevent='select_down()'
      @keydown.up.prevent='select_up()'
      @keyup.enter.stop='select_enter()'
      @keyup.exact='do_filter'
    )
  .scroll.py-2(ref='items')
    //- pre {{filtered_items}}
    //- pre {{selected}}
    div.p-2(v-show='filtered_items && filtered_items.length === 0')
      .text-center
        //- b-icon-info-circle
        | 태그를 추가하려면
        br
        | 이름을 입력해주세요.

    template(v-if='q.length === 0')
      draggable(:class='{dragging: drag}' v-model='filtered_items' handle='.handle' v-bind='drag_options' @start='drag = true' @end='drag_end')
        transition-group(type='transition' :name="!drag ? 'flip-list' : null")
          .item.pl-1.pr-2.py-2.handle(v-for='(item, i) in filtered_items' v-if='item && item.id' :key='item.id' @click='select(item)' :class="{selected:(selected_index===i), checked:(value_array.includes(item.id))}" @mouseover='selected_index = i')
            //- span.handle.handle-grip.float-right: b-icon-grip-horizontal
            span(v-if='!item || !item.name')
            span(v-else-if='!item.ts')
              //- span.mr-2(style='opacity: 0.6') "{{item.name}}"
              //- span.check: b-icon-check
              span.mr-2.rounded.tag-item(style='' :style='{backgroundColor: item.color}') {{item.name}}
              span.rounded-circle.tag-added(style='padding: 0px 1px; margin-right: 3px;')
                b-icon-plus
              span 추가
            span(v-else)
              span.check: b-icon-check
              span.rounded.tag-item(:style='{backgroundColor: (item.color || "#e4e4e4")}') {{item.name}}
              a.float-right.opacity-50(href='#' @click.prevent.stop='' :id='`opt_select_sub_${col.key}_${item.id}`' style='border-left: 0 !important'): b-icon-three-dots(style='color: #555')
              //- span.float-right.text-muted(v-if='item.description') {{item.description}}
              b-popover(:ref='`opt_select_sub_${col.key}_${item.id}`' :target='`opt_select_sub_${col.key}_${item.id}`' triggers='click blur' placement='right' boundary='viewport' custom-class='popover-dropdown')
                div(v-if='document.config.cols_options[col.key][item.id]')
                  span.dropdown-label.px-2 태그이름
                  input.form-control(v-model='document.config.cols_options[col.key][item.id].name' @blur='save_document_col_option')
                  button.btn.btn-default.btn-block.text-left.text-danger(type='button' @click.prevent.stop='remove_col_option(item.id)')
                    b-icon-x.mr-1.opacity-50
                    | 삭제

                  button.btn.btn-default.btn-block.text-left.m-0(v-for='(c, c_idx) in COLORS' type='button' @click.prevent.stop='set_color_col_option(item.id, c)')
                    span.tag-item.color-label.mr-2(:style='{backgroundColor: c}')
                    span {{COLORS_LABEL[c_idx]}}
    template(v-else)
      .item.pl-1.pr-2.py-2.handle(v-for='(item, i) in filtered_items' v-if='item && item.id' :key='item.id' @click='select(item)' :class="{selected:(selected_index===i), checked:(value_array.includes(item.id))}" @mouseover='selected_index = i')
        //- span.handle.handle-grip: b-icon-grip-horizontal
        span(v-if='!item || !item.name')
        span(v-else-if='!item.ts')
          //- span.mr-2(style='opacity: 0.6') "{{item.name}}"
          //- span.check: b-icon-check
          span.mr-2.rounded.tag-item(style='' :style='{backgroundColor: item.color}') {{item.name}}
          span.rounded-circle.tag-added(style='padding: 0px 1px; margin-right: 3px;')
            b-icon-plus
          span 추가
        span(v-else)
          span.check: b-icon-check
          span.rounded.tag-item(:style='{backgroundColor: (item.color || "#e4e4e4")}') {{item.name}}
          a.float-right.opacity-50(href='#' @click.prevent.stop='' :id='`opt_select_sub_${col.key}_${item.id}`' style='border-left: 0 !important'): b-icon-three-dots(style='color: #555')
          //- span.float-right.text-muted(v-if='item.description') {{item.description}}
          b-popover(:ref='`opt_select_sub_${col.key}_${item.id}`' :target='`opt_select_sub_${col.key}_${item.id}`' triggers='hover blur' placement='right' boundary='viewport' custom-class='popover-dropdown')
            div(v-if='document.config.cols_options[col.key][item.id]')
              span.dropdown-label.px-2 태그이름
              input.form-control(v-model='document.config.cols_options[col.key][item.id].name' @blur='save_document_col_option')
              button.btn.btn-default.btn-block.text-left.text-danger(type='button' @click.prevent.stop='remove_col_option(item.id)')
                b-icon-x.mr-1.opacity-50
                | 삭제

              button.btn.btn-default.btn-block.text-left.m-0(v-for='(c, c_idx) in COLORS' type='button' @click.prevent.stop='set_color_col_option(item.id, c)')
                span.tag-item.color-label.mr-2(:style='{backgroundColor: c}')
                span {{COLORS_LABEL[c_idx]}}
</template>

<script>
import * as hangul from 'hangul-js'
import {findIndex, sortBy, isArray} from 'lodash'

import { customAlphabet } from 'nanoid'
const nanoid = customAlphabet('123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz', 5)

import draggable from 'vuedraggable'

// const COLORS = ['#f3a683', '#f7d794', '#778beb', '#e77f67', '#cf6a87']
// const COLORS = ['#f8d2c1', '#fbebc9', '#bbc5f4', '#f3beb2', '#e7b4c3']
// https://flatuicolors.com/palette/ru
const COLORS = ['#546de5', '#e15f41', '#f19066', '#f2c057', '#c44569', '#574b90', '#f78fb3', '#3dc1d3', '#303952', '#999', '#0097e6', '#9c88ff', '#44bd32', '#40739e', '#273c75']
const COLORS_LABEL = ['가지', '자몽', '복숭아', '머스타드', '석류', '포도', '핑크솔트', '소다', '올리브', '회색', '지중해', '라벤더', '풀잎', '호수', '딥블루']

export default {
  name: "SearchTag2",
  props: ['document', 'col', 'value'],
  components: {
    draggable,
  },
  computed: {
    value_array() {
      if (this.value && isArray(this.value)) return this.value
      return []
    },
  },
  data() {
    return {
      COLORS, COLORS_LABEL,
      q: '',
      placeholder: '태그 입력하여 검색 또는 추가',
      items: [],
      filtered_items: [],

      selected: {},
      selected_index: 0,

      last_color_index: 0,

      drag: false,

      drag_options: {
        animation: 200,
        ghostClass: 'ghost',
      },

      saving: false,
      last_selected_ts: 0,
      selecting: false,
    }
  },
  mounted() {
    if (!this.document.config.cols_options) {
      this.document.config.cols_options = {}
    }
    if (!this.document.config.cols_options[this.col.key]) {
      this.document.config.cols_options[this.col.key] = {}
    }

    this.load()
    // if (this.defaultSelected) {
    //   this.selected = this.defaultSelected
    //   this.q = this.defaultSelected.name

    //   this.load()
    //   this.do_filter()
    //   if (this.selected) {
    //     const idx = findIndex(this.filtered_items, (o) => { return o.id == this.selected.id })
    //     console.log({idx}, this.filtered_items, this.selected.id)
    //     if (idx) this.selected_index = idx
    //   }
    // } else {
    //   this.load()
    // }

  },
  methods: {
    async drag_end() {
      let i = 0
      for (const e of this.filtered_items) {
        this.document.config.cols_options[this.col.key][e.id].order = i++
      }
      this.$emit('updated_col_option', this.col)
      setTimeout(() => {
        this.load()
      }, 100);
    },
    set_color_col_option(item_id, color) {
      this.document.config.cols_options[this.col.key][item_id].color = color
      this.save_document_col_option()
    },
    remove_col_option(item_id) {
      if(!confirm(`[${this.document.config.cols_options[this.col.key][item_id].name}] 태그를 삭제하시겠습니까?`)) return false
      // this.document.config.cols_options[this.col.key][item_id] = {}
      delete this.document.config.cols_options[this.col.key][item_id]

      setTimeout(() => {
        this.load()
        this.do_filter()
      }, 0);
      this.save_document_col_option()
    },
    save_document_col_option() {
      this.$emit('updated_col_option', this.col)
      setTimeout(() => {
        this.load()
        this.do_filter()
      }, 0);
    },
    select(item) {
      if (this.saving) return
      this.saving = true

      // console.log('>>>>>', this.last_selected_ts, item.ts)
      // console.log('>>>>>', this.last_selected_ts === item.ts)
      // if (this.last_selected_ts == item.ts) return

      item.ts = Date.now()
      console.log('select ', item)

      // // 이미 선택됨
      // this.last_selected_ts = item.ts

      if (item.id === null) {
        item.id = nanoid(4) + '.' + item.ts
      }

      // if new option
      const is_new = this.items.filter(e => e.id === item.id).length === 0
      if (is_new) {
        console.log('pushed opt', item)
        this.document.config.cols_options[this.col.key][item.id] = item

        this.last_color_index = (this.last_color_index) % COLORS.length
        this.$emit('updated_col_option', this.col)
      }

      this.$emit('updated', this.col, item)
      setTimeout(() => {
        this.load()
        // if (is_new) {
        //   this.do_filter()
        // }
        this.do_filter()

        this.saving = false
      }, 300);
      return
    },
    select_down() {
      this.selected_index = Math.min(this.filtered_items.length-1, this.selected_index+1)

      const items = this.$refs.items.querySelectorAll('.item')
      if (items[this.selected_index]) {
        items[this.selected_index].scrollIntoView({block: 'nearest'})
      }
    },
    select_up() {
      this.selected_index = Math.max(0, this.selected_index-1)
      const items = this.$refs.items.querySelectorAll('.item')
      if (items[this.selected_index]) {
        items[this.selected_index].scrollIntoView({block: 'nearest'})
      }
    },
    select_enter() {
      if (this.selecting) return
      this.selecting = true
      setTimeout(() => {
        this.selecting = false
      }, 100);

      if (this.filtered_items.length === 1) {
        return this.select(this.filtered_items[0])
      }
      const item = this.filtered_items[this.selected_index]
      if (item) {
        this.select(item)
      }
    },
    do_filter() {
      let q = String(this.q).trim()
      if (q.length === 0) {
        this.filtered_items = this.items
        return
      }

      let matched_exact = false
      this.filtered_items = [].concat(this.items).filter(e => {
        let matched_name = false
        if (q.length >= 1) {
          if (q.length === 1) {
            // 첫번째 글짜 미입력시 검색안함
            if (!hangul.isComplete(q)) return false
          }
          const v = hangul.search(e.name.toLowerCase(), q.toLowerCase())
          if (v >= 0) {
            matched_name = true
            if (q == e.name) {
              matched_exact = true
            }
          }
        }
        // if (q.length >= 2) {
        //   matched_email = e.email.replace(/\.|@/g, '').match(new RegExp(q, 'im'))
        // }
        // if (q.length >= 1) {
        //   matched_name = e.name.replace(/\s/g, '').match(new RegExp(q, 'im'))
        // }
        return matched_name
      })
      if (this.filtered_items.length === 0 || !matched_exact) {
        this.filtered_items.push({
          id: nanoid(4) + '.' + Date.now(),
          name: q,
          color: COLORS[this.last_color_index],
        })
      }
    },
    load() {
      setTimeout(() => {
        this.$refs.input?.focus()
      }, 300);

      this.items = sortBy(Object.values(this.document.config.cols_options[this.col.key]), 'order')
      this.filtered_items = this.items

      this.last_color_index = (this.items.length+1) % COLORS.length

    }
  },
};
</script>
