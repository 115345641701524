<style lang="sass" scoped>
.label
  font-size: 14px
  font-weight: 600
.date-picker-inline
  border: 0
</style>
<template lang="pug">
div
  .mx-1
    .d-flex.mb-1
      input.form-control.query#search-input(
        :class='{"is-invalid": is_invalid}'
        v-b-tooltip.manual.left='`날짜 형식 오류`'
        type='text' v-model='q' ref='input'
        placeholder='날짜 입력'
        @keyup.enter.prevent.stop='select_enter()'
        @blur='select_enter'
        @focus='update_date_target(`0`)'
      )
      input.form-control.query#search-input01.ml-1(
        :class='{"is-invalid": is_invalid01}'
        v-b-tooltip.manual.left='`시간 형식 오류`'
        type='text' v-model='q01'
        placeholder='시간 입력'
        @keyup.enter.prevent.stop='select_enter01()'
        v-show='use_time == "Y"'
        @blur='select_enter01'
      )
    .d-flex.mb-1
      input.form-control.query#search-input10(
        :class='{"is-invalid": is_invalid10}'
        v-b-tooltip.manual.left='`날짜 형식 오류`'
        type='text' v-model='q10' ref='input10'
        placeholder='종료일 입력'
        @keyup.enter.prevent.stop='select_enter10()'
        @blur='select_enter10'
        v-show='use_end_date == "Y"'
        @focus='update_date_target(`1`)'
      )
      input.form-control.query#search-input11.ml-1(
        :class='{"is-invalid": is_invalid11}'
        v-b-tooltip.manual.left='`시간 형식 오류`'
        type='text' v-model='q11'
        placeholder='시간 입력'
        @keyup.enter.prevent.stop='select_enter11()'
        v-show='use_end_date == "Y" && use_time == "Y"'
        @blur='select_enter11'
      )

    div(style='height: 266px')
      //- (style='width: 250px')
      //- template(v-if='use_end_date == "Y"')
      //-   //- h5 {{daterange.show}}
      //-   //- div(v-if='daterange.show')
      //-   div(:key='daterange.show')
      //-     date-picker.date-picker-inline(ref='calendar_daterange' locale='ko' v-model='daterange' mode='date' title-position='left' is-expanded is-range)
      //- template(v-else)
      date-picker.date-picker-inline(ref='calendar_date' locale='ko' transition='slide-v' v-model='date0' mode='date' title-position='left' is-expanded)
    //- .d-flex.py-1(v-if='mode.includes("range")')
      span.label.pl-2 종료일
      .ml-auto
        b-form-checkbox(v-model='use_end_date' value='Y' unchecked-value='N' switch @input='select_option')
    //- .d-flex.py-1(v-if='mode.includes("time")')
      span.label.pl-2 시간
      .ml-auto
        b-form-checkbox(v-model='use_time' value='Y' unchecked-value='N' switch @input='select_option')
  button.btn.btn-light.btn-block.mt-1(type='button' style='margin-bottom: -3px; border-radius: 0 0 4px 4px' @click='blur_popover') 확인


</template>

<script>

import moment from 'moment'
import {isString, isObject} from 'lodash'

import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  name: "SearchTag2",
  props: ['document', 'col', 'value', 'mode'],
  components: {
    DatePicker,
  },
  data() {
    return {
      date0: '',
      date_target: '',
      daterange: {
        show: Date.now(),
        start: '',
        end: '',
      },
      is_invalid: false,
      is_invalid01: false,
      is_invalid10: false,
      is_invalid11: false,
      q: '',
      q01: '',
      q10: '',
      q11: '',
      date: null,
      $d: null,
      $d01: null,
      $d10: null,
      $d11: null,

      use_end_date: 'N',
      use_time: 'N',

      items: [],
      filtered_items: [],

      selected: {},
      selected_index: 0,

      last_color_index: 0,

      drag: false,

      drag_options: {
        animation: 200,
        ghostClass: 'ghost',
      },

      format: 'YYYY.MM.DD',
      format_time: 'A h:mm',

      ready_parent_update: false,
    }
  },
  watch: {
    'date0'(after, before) {
      if (!after) return

      // this.$d = moment(after.toISOString())
      // this.q = this.$d.format(this.format)
      // this.update_parent()
      // console.log(before, after)
      if (this.date_target == '0') {
        this.$d = moment(after.toISOString())
        this.q = this.$d.format(this.format)
        this.update_parent()
      }
      else if (this.date_target == '1') {
        this.$d10 = moment(after.toISOString())
        this.q10 = this.$d10.format(this.format)
        this.update_parent()
      }
    },
    // 'daterange'(after, before) {
    //   if (!after.start || !after.end) return

    //   this.$d = moment(after.start.toISOString())
    //   this.q = this.$d.format(this.format)
    //   this.$d10 = moment(after.end.toISOString())
    //   this.q10 = this.$d10.format(this.format)

    //   // 종료일 끄는 경우
    //   this.date0 = after.start

    //   this.daterange.show = Date.now()
    //   this.update_parent()
    // }
  },
  mounted() {
    this.load()
  },
  methods: {
    blur_popover() {
      this.$root.$emit("bv::hide::popover")
    },
    update_date_target(n) {
      this.date_target = n
      if (n == '0') {
        this.date0 = this.$d.toDate()
        setTimeout(() => {
          this.$refs.calendar_date?.move(this.date0)
        }, 300);
      }
      else if (n == '1') {
        this.date0 = this.$d10.toDate()
        setTimeout(() => {
          this.$refs.calendar_date?.move(this.date0)
        }, 300);
      }
    },
    fill() {
      if(!this.q) {
        if (this.$d) {
          this.q = this.$d.format(this.format)
        } else {
          this.q = moment().format(this.format)
        }
      }
    },
    fill10() {
      if(!this.q10) {
        if (this.$d10) {
          this.q10 = this.$d10.format(this.format)
        } else {
          this.q10 = moment().format(this.format)
        }
      }
    },
    fill01() {
      if(!this.q01) {
        if (this.$d01) {
          this.q01 = this.$d01.format(this.format_time)
        } else {
          this.q01 = ''
        }
      }
    },
    fill11() {
      if(!this.q11) {
        if (this.$d11) {
          this.q11 = this.$d11.format(this.format_time)
        } else {
          this.q11 = ''
        }
      }
    },
    get_date_from_string(value) {
      {
        const $d = moment(value, 'YYYY-MM-DD', true)
        if ($d.isValid()) {
          return $d
        }
      }
      {
        const $d = moment(value, 'YYYY-MM-DD', true)
        if ($d.isValid()) {
          return $d
        }
      }
      {
        const $d = moment(value, 'YYYY.MM.DD', true)
        if ($d.isValid()) {
          return $d
        }
      }
      {
        const $d = moment(value, 'YYYY/MM/DD', true)
        if ($d.isValid()) {
          return $d
        }
      }
      {
        const $d = moment(value, 'YYYYMMDD', true)
        if ($d.isValid()) {
          return $d
        }
      }
      {
        const $d = moment(value, 'MM.DD', true)
        if ($d.isValid()) {
          $d.year(moment().year())
          return $d
        }
      }
      {
        const $d = moment(value, 'MM-DD', true)
        if ($d.isValid()) {
          $d.year(moment().year())
          return $d
        }
      }
      {
        const $d = moment(value, 'MMDD', true)
        if ($d.isValid()) {
          $d.year(moment().year())
          return $d
        }
      }
      {
        const $d = moment(value, 'LL', 'ko', true)
        if ($d.isValid()) {
          // $d.year(moment().year())
          return $d
        }
      }
      {
        const $d = moment(value, 'YYYY-MM', true)
        if ($d.isValid()) {
          $d.date(1)
          return $d
        }
      }
      {
        const $d = moment(value, 'YYYY.MM', true)
        if ($d.isValid()) {
          $d.date(1)
          return $d
        }
      }
      {
        const $d = moment(value, 'YYYYMM', true)
        if ($d.isValid()) {
          $d.date(1)
          return $d
        }
      }
      return null
    },
    get_time_from_string(date, value) {
      value = value.replace(/\시/g, '')
      {
        const $d = moment(date + ' ' + value, 'YYYY-MM-DD H:mm:ss', true)
        if ($d.isValid()) {
          return $d
        }
      }
      {
        const $d = moment(date + ' ' + value, 'YYYY-MM-DD HHmmss', true)
        if ($d.isValid()) {
          return $d
        }
      }
      {
        const $d = moment(date + ' ' + value, 'YYYY-MM-DD H:mm', true)
        if ($d.isValid()) {
          return $d
        }
      }
      {
        const $d = moment(date + ' ' + value, 'YYYY-MM-DD HHmm', true)
        if ($d.isValid()) {
          return $d
        }
      }
      {
        const $d = moment(date + ' ' + value, 'YYYY-MM-DD A h:m', 'en', true)
        if ($d.isValid()) {
          return $d
        }
      }
      {
        const $d = moment(date + ' ' + value, 'YYYY-MM-DD h:m A', 'en', true)
        if ($d.isValid()) {
          return $d
        }
      }
      {
        const $d = moment(date + ' ' + value, 'YYYY-MM-DD A h', 'en', true)
        if ($d.isValid()) {
          return $d
        }
      }
      {
        const $d = moment(date + ' ' + value, 'YYYY-MM-DD h A', 'en', true)
        if ($d.isValid()) {
          return $d
        }
      }
      {
        const $d = moment(date + ' ' + value, 'YYYY-MM-DD LT', 'en', true)
        if ($d.isValid()) {
          return $d
        }
      }
      {
        const $d = moment(date + ' ' + value, 'YYYY-MM-DD A h:m', 'ko', true)
        if ($d.isValid()) {
          return $d
        }
      }
      {
        const $d = moment(date + ' ' + value, 'YYYY-MM-DD h:m A', 'ko', true)
        if ($d.isValid()) {
          return $d
        }
      }
      {
        const $d = moment(date + ' ' + value, 'YYYY-MM-DD A h', 'ko', true)
        if ($d.isValid()) {
          return $d
        }
      }
      {
        const $d = moment(date + ' ' + value, 'YYYY-MM-DD h A', 'ko', true)
        if ($d.isValid()) {
          return $d
        }
      }
      {
        const $d = moment(date + ' ' + value, 'YYYY-MM-DD LT', 'ko', true)
        if ($d.isValid()) {
          return $d
        }
      }
      return null
    },
    select_option() {
      if (this.use_end_date != 'Y') {
        this.update_date_target('0')
      } else {
        // this.update_date_target('1')
      }
      setTimeout(() => {
        this.update_parent()
      }, 100);
    },
    update_parent() {
      if (!this.ready_parent_update) return

      const o = {
        use_end_date: this.use_end_date === 'Y' ? 'Y' : 'N',
        use_time: this.use_time === 'Y' ? 'Y' : 'N',
      }
      o.date = this.$d.format('YYYY-MM-DD')
      if (this.$d01) {
        o.time = this.$d01.format('HH:mm:ss')
      } else {
        if (o.use_time == 'Y') {
          o.time = '00:00:00'
        }
      }
      if (o.use_end_date == 'Y') {
        if (this.$d10) {
          o.end_date = this.$d10.format('YYYY-MM-DD')
        }
        if (this.$d11) {
          o.end_time = this.$d11.format('HH:mm:ss')
        } else {
          if (o.use_time == 'Y') {
            o.end_time = '00:00:00'
          }
        }
      }
      // console.log('>>', this.daterange)
      // console.log('>>', o)
      this.$emit('updated', this.col, o)
    },
    select_enter() {
      this.fill()

      const $d = this.get_date_from_string(this.q)
      if ($d === null) {
        this.is_invalid = true
        this.$root.$emit('bv::show::tooltip', 'search-input')
      } else {
        this.is_invalid = false
        this.$root.$emit('bv::hide::tooltip', 'search-input')

        this.q = $d.format(this.format)
        const is_changed = this.$d ? this.$d.format('YYYYMMDD') != $d.format('YYYYMMDD') : true
        this.$d = $d.clone()

        if (is_changed) {
          // if (this.use_end_date == 'Y') {
          //   this.daterange.start = this.$d.toDate()
          //   this.daterange.show = Date.now()
          //   setTimeout(() => {
          //     this.$refs.calendar_daterange?.move(this.daterange.start)
          //   }, 300);
          // } else {
          //   this.date0 = this.$d.toDate()
          //   setTimeout(() => {
          //     this.$refs.calendar_date?.move(this.date0)
          //   }, 300);
          // }
          this.update_parent()
        }
      }
    },
    select_enter10() {
      this.fill10()

      const $d = this.get_date_from_string(this.q10)
      if ($d === null) {
        this.is_invalid10 = true
        this.$root.$emit('bv::show::tooltip', 'search-input10')
      } else {
        this.is_invalid10 = false
        this.$root.$emit('bv::hide::tooltip', 'search-input10')

        this.q10 = $d.format(this.format)
        this.$d10 = $d.clone()

        this.daterange.end = this.$d10.toDate()
        this.daterange.show = Date.now()

        this.update_parent()
      }
    },
    select_enter01() {
      this.fill01()
      if (!this.q01) return

      const $d = this.get_time_from_string(moment().format('YYYY-MM-DD'), this.q01)
      if ($d === null) {
        this.is_invalid01 = true
        this.$root.$emit('bv::show::tooltip', 'search-input01')
      } else {
        this.is_invalid01 = false
        this.$root.$emit('bv::hide::tooltip', 'search-input01')

        this.q01 = $d.format(this.format_time)
        this.$d01 = $d.clone()
        this.update_parent()
      }
    },
    select_enter11() {
      this.fill11()
      if (!this.q11) return

      const $d = this.get_time_from_string(moment().format('YYYY-MM-DD'), this.q11)
      if ($d === null) {
        this.is_invalid11 = true
        this.$root.$emit('bv::show::tooltip', 'search-input11')
      } else {
        this.is_invalid11 = false
        this.$root.$emit('bv::hide::tooltip', 'search-input11')

        this.q11 = $d.format(this.format_time)
        this.$d11 = $d.clone()
        this.update_parent()
      }
    },
    load() {
      setTimeout(() => {
        this.$refs.input?.focus()
      }, 300);

      if (this.value) {
        // console.log('this.value', this.value)
        const v = Object.assign({}, this.value)
        if (isString(v)) {
          this.q = v
          this.select_enter()
        }
        else if (isObject(v)) {
          this.use_end_date = v.use_end_date == 'Y' ? 'Y' : 'N'
          this.use_time = v.use_time == 'Y' ? 'Y' : 'N'

          this.q = v.date
          this.select_enter()
          if (this.use_time == 'Y') {
            this.q01 = v.time
            this.select_enter01()
          }
          if (this.use_end_date == 'Y') {
            this.q10 = v.end_date
            this.select_enter10()
            if (this.use_time == 'Y') {
              this.q11 = v.end_time
              this.select_enter11()
            }
          }
        } else {

        }
      }
      if (!this.q) this.q = moment().format(this.format)
      if (!this.q01) this.q01 = ''
      if (!this.q10) this.q10 = moment().format(this.format)
      if (!this.q11) this.q11 = ''

      if (this.use_end_date == 'Y') {
        if (this.$d) {
          this.daterange.start = this.$d.clone().toDate()
        }
        if (this.$d10) {
          this.daterange.end = this.$d10.clone().toDate()
        }
        this.daterange.show = Date.now()
      }
      if (this.$d) {
        this.date0 = this.$d.clone().toDate()
      }
      this.date_target = '0'

      setTimeout(() => {
        this.ready_parent_update = true
      }, 300);
      // this.q = '20200101'
      // this.q10 = '20200102'
      // this.use_end_date = 'Y'
      // this.use_time = 'Y'
      // this.select_enter()
      // this.select_enter10()
      // this.q = this.value

    }
  },
};
</script>
