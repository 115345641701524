import { render, staticRenderFns } from "./DocumentRecord.vue?vue&type=template&id=534af294&scoped=true&lang=pug&"
import script from "./DocumentRecord.vue?vue&type=script&lang=js&"
export * from "./DocumentRecord.vue?vue&type=script&lang=js&"
import style0 from "./DocumentRecord.vue?vue&type=style&index=0&id=534af294&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "534af294",
  null
  
)

export default component.exports